import React from 'react';
import { Box, Typography } from '@mui/material';
import moment from 'moment';

const Footer = () => {
  const currentYear = moment().format('YYYY');

  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'white',
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        p: 1,
        width: '100%',
        zIndex: 900,
      }}
    >
      <Typography variant='body2' sx={{ fontSize: '0.75rem', lineHeight: '1', mt: '-3px' }}>
        © {currentYear} SPORTS SOUTH, LLC &nbsp;&nbsp;|&nbsp;&nbsp; ALL RIGHTS RESERVED
      </Typography>
    </Box>
  );
};

export default Footer;
