export const ProductionTypes = [
	{ product_type_id: 100, description: 'Mailer Publications' },
	{ product_type_id: 200, description: 'Order Stuffer Publications' },
	{ product_type_id: 300, description: 'Email Publications' },
	{ product_type_id: 400, description: 'Home Page Publications' },
	{ product_type_id: 500, description: 'Dealer Summit Marketing' },
];

export const ProductionProducts = [
	{ production_products_id: 1000, description: 'Single Page Print', cost: '1050.00' },
	{ production_products_id: 1100, description: 'Spread Pages Print', cost: '1750.00' },
	{ production_products_id: 1200, description: 'Strip Ad Print', cost: '450.00' },
	{ production_products_id: 1300, description: 'Email Banner Digital Ad', cost: '600.00' },
	{ production_products_id: 1350, description: 'Exclusive Email Blast', cost: '3000.00' },
	{ production_products_id: 1400, description: 'Home Page Digital Standard Ad', cost: '1000.00' },
	{ production_products_id: 1500, description: 'Home Page Digital Large Ad', cost: '2200.00' },
	{ production_products_id: 1700, description: 'Dealer Summit Trash Can Wrap', cost: '3000.00' },
];

export const production_types_products_links = [
	{
		product_type_id: 100,
		products: [1000, 1100, 1200],
	},
	{
		product_type_id: 200,
		products: [1000, 1100, 1200],
	},
	{
		product_type_id: 300,
		products: [1300, 1350],
	},
	{
		product_type_id: 400,
		products: [1400, 1500],
	},
	{
		product_type_id: 500,
		products: [1700],
	},
];

export const Productions = [
	{
		production_id: 900,
		product_type_id: 500,
		description: '2025 Dealer Summit',
	},
	{
		production_id: 1000,
		product_type_id: 100,
		description: 'Turkey Flyer',
	},
	{
		production_id: 1100,
		product_type_id: 100,
		description: 'PRC Flyer',
	},
	{
		production_id: 1200,
		product_type_id: 200,
		description: 'Reloading Stuffer',
	},
	{
		production_id: 1250,
		product_type_id: 200,
		description: 'Knife Stuffer',
	},
	{
		production_id: 1300,
		product_type_id: 300,
		description: 'Summer Savings Email',
	},
	{
		production_id: 1400,
		product_type_id: 300,
		description: 'New Products Email',
	},
	{
		production_id: 1500,
		product_type_id: 400,
		description: 'By-Weekly Banners',
	},
	{
		production_id: 1600,
		product_type_id: 400,
		description: 'Feature Brand Banners',
	},
];

const moreData = [
	{
		id: 1,
	},
	{
		id: 2,
	},
];
