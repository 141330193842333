import React, { useState, useEffect } from 'react';
import { Box, Modal, TextField, Typography, Button } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import TableRowActions from './Components/TableRowActions';
import getColumns from './Components/TableColumns';
import LoadingSpinner from '../../../_GlobalComponents/LoadingSpinner';
import TableHeaderActions from './Components/TableHeaderActions';
import OrderConfirmModal from '../OrderConfirmModal/OrderConfirmModal';
import RowDetailModal from './Components/RowDetailModal';

const SuggestedOrderTable = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState('');
  const [dataRefreshToggle, setDataRefreshToggle] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemNumber, setSelectedItemNumber] = useState(null);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('#000');
  const [pageTitle, setPageTitle] = useState('Smart Ordering');

  useEffect(() => {
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const tokenFromUrl = pathParts[pathParts.length - 1];
    setToken(tokenFromUrl);
    setIsLoading(true);

    axios
      .post('/suggestedOrders', {
        customerNumber: tokenFromUrl,
      })
      .then((response) => {
        const { result, message, message_color, page_title } = response.data;

        // Update message-related state variables
        setMessage(message);
        setMessageColor(message_color || '#000');
        setPageTitle(page_title);

        if (result === 'success') {
          const dataWithCheckbox = response.data.details.map((item) => ({
            ...item,
            isChecked: false,
          }));
          setData(dataWithCheckbox);
        } else if (result === 'empty') {
          // No items to display
          setData([]);
        } else if (result === 'error') {
          // Handle error case
          console.error('Error fetching data:', message);
          setData([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, [dataRefreshToggle]);

  const handleCheckChange = (row, isChecked) => {
    const updatedData = data.map((item) =>
      item.item_number === row.original.item_number ? { ...item, isChecked } : item
    );
    setData(updatedData);

    // Update selectedRows after changing isChecked
    const updatedSelectedRows = updatedData.filter((item) => item.isChecked);
    setSelectedRows(updatedSelectedRows);
  };

  const handleButtonClick = () => {
    if (selectedRows.length > 0) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 0);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleItemOpenModal = (itemNumber) => {
    setSelectedItemNumber(itemNumber);
    setModalOpen(true);
  };

  const handleItemCloseModal = () => {
    setModalOpen(false);
    setSelectedItemNumber(null);
  };

  const handleConfirmOrder = (poNumber, shippingMethod) => {
    // Prepare the data to send to the backend
    const items = selectedRows.map((row) => ({
      item_number: row.item_number,
      suggested_qty: row.suggested_qty,
    }));

    axios
      .post('/suggestedOrders/placeOrder', {
        accountNumber: token,
        poNumber: poNumber,
        shippingMethod: shippingMethod,
        items: items,
      })
      .then((response) => {
        console.log('Order placed successfully');
        setIsModalOpen(false);
        // Handle success, e.g., show a success message
      })
      .catch((error) => {
        console.error('Error placing order:', error);
        // Handle error, e.g., show an error message
      });
  };

  const handleOrderPlaced = () => {
    // Reset selected rows
    setSelectedRows([]);
    // Clear data so that old data is not displayed
    setData([]);
    // Toggle dataRefreshToggle to trigger useEffect
    setDataRefreshToggle((prev) => !prev);
  };

  const handleQtyChange = (item_number, newQty) => {
    const updatedData = data.map((item) =>
      item.item_number === item_number ? { ...item, suggested_qty: newQty } : item
    );
    setData(updatedData);

    // Update selectedRows after changing quantities
    const updatedSelectedRows = updatedData.filter((item) => item.isChecked);
    setSelectedRows(updatedSelectedRows);
  };

  const handleClearSelection = () => {
    // Uncheck all items in data
    const updatedData = data.map((item) => ({ ...item, isChecked: false }));
    setData(updatedData);
    setSelectedRows([]);
  };

  const handleSelectAll = () => {
    // Check all items in data
    const updatedData = data.map((item) => ({ ...item, isChecked: true }));
    setData(updatedData);
    setSelectedRows(updatedData);
  };

  const tableColumns = getColumns({ handleItemOpenModal, handleQtyChange });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <LoadingSpinner isLoading={isLoading} />

      <MaterialReactTable
        columns={tableColumns}
        data={data}
        enableHiding={false}
        enableToolbarInternalButtons={false}
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 20 },
          sorting: [{ id: 'last_ordered_days', ascending: true }],
        }}
        positionToolbarAlertBanner='bottom'
        autoResetPageIndex={false}
        enableStickyHeader
        enableRowActions
        isLoading={isLoading}
        renderLoadingOverlay={() => null}
        localization={{
          noRecordsToDisplay: isLoading ? '' : 'No Records To Display',
        }}
        renderRowActions={({ row }) => (
          <TableRowActions row={row} onCheckChange={handleCheckChange} />
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <TableHeaderActions
            table={table}
            selectedRows={selectedRows}
            handleButtonClick={handleButtonClick}
            handleClearSelection={handleClearSelection}
            handleSelectAll={handleSelectAll}
            data={data}
            message={message}
            messageColor={messageColor}
            pageTitle={pageTitle}
          />
        )}
        muiTablePaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
          },
        }}
        muiTableContainerProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 10,
          },
        }}
      />

      {/* Modal for confirming order */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <OrderConfirmModal
          selectedRows={selectedRows}
          handleClose={handleCloseModal}
          handleConfirm={handleConfirmOrder}
          customerNumber={token}
          handleOrderPlaced={handleOrderPlaced}
        />
      </Modal>
      <RowDetailModal
        open={modalOpen}
        handleClose={handleItemCloseModal}
        itemNumber={selectedItemNumber}
      />
    </Box>
  );
};

export default SuggestedOrderTable;
