import React from 'react';

import { Box, useTheme } from '@mui/material';

// import AdvertisingCoop from './components/AdvertisingCoop/AdvertisingCoop';

// import AdvertisingDefault from './components/AdvertisingDefault';
// import AdvertisingMenu from './components/AdvertisingMenu';
// import CoopFund from './components/AdvertisingCoop/CoopFund';
// import StaffCards from './components/StaffCards/StaffCards';
// import PurchaseAdvertising from './components/PurchaseAdvertising/PurchaseAdvertising';

const Advertising = ({ children }) => {
	const theme = useTheme();
	//const { name } = useContext(ManufacturerContextState);
	return (
		<Box sx={{ backgroundColor: theme.palette.primary.lightScreen }}>
			{children}
			{/*<AdvertisingContext>
			
			
			<Box sx={{ py: 2, borderBottom: 'solid 1px silver' }}>
				<Container maxWidth='xl'>
					<AdvertisingMenu />
				</Container>
			</Box> 
			 <Routes>
				<Route path='/coop' element={<AdvertisingCoop />} />
				<Route path='/coop-fund-form/:fundId' element={<CoopFund />} />
				<Route path='/manufacturer/advertising/purchase-advertising' element={<PurchaseAdvertising />} />
				<Route path='/*' element={<AdvertisingDefault />} />
			</Routes> 
		</AdvertisingContext>
		*/}
		</Box>
	);
};

export default Advertising;
