import React from 'react';
import { Box, useTheme } from '@mui/material';
import Caption from '../../Caption';

const featureProductData = [
	{
		product_id: 174631,
		product_code: 16681,
		description:
			'Caldwell 440907 Rock BR Shooting Rest 3 Lobe Bag, Medium Varmint Front Bag 3 Lobe Unfilled, Front Bag Filled',
		product_manufacturer_id: 1282,
		model: 'Rock',
		series: 'BR',
		integer_data: 16681,
	},
	{
		product_id: 174632,
		product_code: 16682,
		description: 'Caldwell 323225 Rock Jr. Prefilled Cast Iron Medium Varmint Front Bag 5 lbs',
		product_manufacturer_id: 1282,
		model: 'Rock',
		series: 'Jr.',
		integer_data: 16682,
	},
	{
		product_id: 174634,
		product_code: 16685,
		description: 'Caldwell 191743 Tack Driver Shooting Rest Bag Sandbag Rest Unfilled Nylon',
		product_manufacturer_id: 1282,
		model: 'Tack Driver',
		series: '',
		integer_data: 16685,
	},
	{
		product_id: 178338,
		product_code: 38621,
		description: "Caldwell 248885 DeadShot Shooter's Bag Empty Dark Green 600D Polyester Front and Rear Bag 8 lbs",
		product_manufacturer_id: 1282,
		model: 'DeadShot',
		series: "Shooter's Bag",
		integer_data: 38621,
	},
	{
		product_id: 179859,
		product_code: 43925,
		description: 'Caldwell 428334 Lead Sled Shooting Rest Weight Bag Lead Shot Bag(Unfilled)',
		product_manufacturer_id: 1282,
		model: 'Lead Sled',
		series: '',
		integer_data: 43925,
	},
	{
		product_id: 179860,
		product_code: 43927,
		description: 'Caldwell 574662 Handy Rest NXT Gun Rest',
		product_manufacturer_id: 1282,
		model: 'NXT',
		series: 'Handy Rest',
		integer_data: 43927,
	},
	{
		product_id: 179861,
		product_code: 43928,
		description: 'Caldwell 548664 Steady Rest NXT Shooting Rest None Gray/Green 26" Long',
		product_manufacturer_id: 1282,
		model: 'NXT',
		series: 'Steady Rest',
		integer_data: 43928,
	},
	{
		product_id: 179862,
		product_code: 43929,
		description:
			'Caldwell 939333 DeadShot Shooting Bag Combo Prefilled Dark Green 600D Polyester Includes Front and Rear Bags',
		product_manufacturer_id: 1282,
		model: 'DeadShot',
		series: 'Shooting Bag Combo',
		integer_data: 38621,
	},
	{
		product_id: 182184,
		product_code: 53980,
		description: 'Caldwell 252552 Stable Table  Steel/Plastic 23" W x 34" L',
		product_manufacturer_id: 1282,
		model: 'Stable Table',
		series: '',
		integer_data: 53980,
	},
	{
		product_id: 182185,
		product_code: 53981,
		description: 'Caldwell 820585  Resetting Target Airgun AR500 Steel Black/Orange Impact Enhancement Motion 1.50',
		product_manufacturer_id: 1282,
		model: 'Resetting',
		series: '',
		integer_data: 53981,
	},
];

const SideItems = () => {
	const theme = useTheme();
	return (
		<Box display={'flex'} flexDirection={'column'} sx={{ maxHeight: '100%', overfowY: 'hidden' }}>
			<Caption caption='Feature Items:' />
			<Box
				sx={{
					flex: 1,
					overflowY: 'auto',
					display: 'grid',
					gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: '1fr' },
				}}
			>
				{featureProductData.map((e) => (
					<Box
						key={e}
						display={'flex'}
						alignItems={'center'}
						sx={{
							backgroundColor: 'rgba(255,255,255)',
							boxShadow: '1px 1px 6px rgba(0,0,0,.2)',
							minHeight: '125px',
							m: 1,
							mb: 3,
						}}
					>
						<img
							style={{
								flex: 0,
								display: 'block',
								height: '100%',
								maxHeight: '100px',
								minWidth: '100px',
								maxWidth: '100px',
								objectFit: 'contain',
								padding: '12px',
							}}
							src={`https://media.server.theshootingwarehouse.com/small/${e.integer_data}.jpg`}
							alt={`product ${e}`}
						/>
						<Box
							sx={{ flex: 1, p: 1, cursor: 'pointer' }}
							onClick={() =>
								window.open(
									`https://www.theshootingwarehouse.com/?search_text=(${e.product_code})`,
									'_blank'
								)
							}
						>
							<Box sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.palette.primary.main }}>
								Model {e.model} {e.series}
							</Box>
							<Box sx={{ fontSize: '12px' }}>
								<span style={{ color: theme.palette.secondary.main }}>{e.product_code}</span> |{' '}
								{e.description}
							</Box>
							{/* <Box sx={{ fontSize: '12px', fontWeight: 'bold' }}>
								<span style={{ color: theme.palette.primary.main }}>SS#</span> 16685 |{' '}
								<span style={{ color: theme.palette.primary.main }}>MFR#</span> LM34576-XP
							</Box> */}
							<Box sx={{ textAlign: 'right', py: 1 }}>$1999.99</Box>
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default SideItems;
