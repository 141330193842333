import { createSlice } from '@reduxjs/toolkit';

export const dataAuditSlice = createSlice({
  name: 'dataAudit',
  initialState: {
    mfrDataAuditSelectedItems: [1, 2, 3, 4, 5, 6, 7],
    mfrIdForTableData: 0,
    modalMfrListOpen: false,
  },
  reducers: {
    setMfrDataAuditSelectedItems: (state, action) => {
      state.mfrDataAuditSelectedItems = action.payload;
    },
    resetMfrDataAuditSelectedItems: (state) => {
      state.mfrDataAuditSelectedItems = [];
    },

    setMfrIdForTableData: (state, action) => {
      state.mfrIdForTableData = action.payload;
    },
    resetMfrIdForTableData: (state) => {
      state.mfrIdForTableData = 0;
    },
    setModalMfrListOpen: (state, action) => {
      state.modalMfrListOpen = action.payload;
    },
  },
});

export const { setMfrDataAuditSelectedItems, resetMfrDataAuditSelectedItems, setMfrIdForTableData, resetMfrIdForTableData, setModalMfrListOpen } = dataAuditSlice.actions;
export default dataAuditSlice.reducer;
