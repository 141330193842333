import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const TableHeaderActions = ({
  table,
  handleButtonClick,
  selectedRows,
  handleClearSelection,
  handleSelectAll,
  data,
  message,
  messageColor,
  pageTitle,
}) => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap={2}
      sx={{ paddingBottom: '16px', minHeight: '250px' }}
    >
      {/* Title and Description */}
      <Box display='flex' alignItems='baseline' flexWrap='wrap' gap={1}>
        <Typography variant='h5' color='secondary'>
          {pageTitle}
        </Typography>
      </Box>

      {/* Buttons */}
      <Box
        display='flex'
        alignItems='center'
        gap={1}
        sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}
      >
        {/* Conditionally render 'Select All' or 'Clear Selection' based on selectedRows */}
        {data.length > 0 && (
          <>
            {selectedRows.length > 0 ? (
              <Button
                variant='outlined'
                color='secondary'
                onClick={handleClearSelection}
                sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}
              >
                Clear Selection
              </Button>
            ) : (
              <Button
                variant='outlined'
                onClick={handleSelectAll}
                sx={{ fontSize: '0.75rem', fontWeight: 'bold' }}
              >
                Select All
              </Button>
            )}

            {/* Confirm Order Button */}
            <Button variant='contained' onClick={handleButtonClick}>
              Confirm Order
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TableHeaderActions;
