import React from 'react';
import { AppBar, Box, Toolbar, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from './Components/Logo';
import SearchBox from './Components/SearchBox';
import ButtonGroup from './Components/ButtonGroup';

const TSWNavbar = () => {
  const theme = useTheme();

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='fixed'
        sx={{
          backgroundColor: theme.palette.tsw.primary,
          height: '120px',
          borderBottom: `3px solid ${theme.palette.tsw.secondary}`,
          transition: '0.2s',
          zIndex: 500,
        }}
      >
        <Toolbar
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Grid container direction='row' sx={{ maxWidth: '85%' }}>
            <Grid item xs={6}>
              <Grid container direction='row' spacing={1}>
                <Grid item xs={12}>
                  <Logo />
                </Grid>
                <Grid item xs={12}>
                  <SearchBox />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <ButtonGroup />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TSWNavbar;
