import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';

import {
	Box,
	Button,
	IconButton,
	Snackbar,
	TextField,
	Typography,
	useTheme,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
	return (
		<MuiAlert
			elevation={6}
			ref={ref}
			variant='filled'
			{...props}
		/>
	);
});

// Primary Component
const DetailPanelProductDetails = ({ row, user_id }) => {
	// INITs
	const theme = useTheme();

	// Local State
	const [data, setData] = useState([]);
	const [openSnack, setOpenSnack] = useState(false);
	const [openDeleteSnack, setOpenDeleteSnack] = useState(false);

	// Local Refrences
	const essentialFacetValues = useRef({});

	// Function
	const updateEditFacetValues = (e) => {
		console.log('update value: ' + e.target.value);
		const obj = {};
		Object.keys(essentialFacetValues.current).map(
			(fact, idx) =>
				(obj[fact] =
					fact === e.target.name
						? e.target.value
						: essentialFacetValues.current[fact])
		);
		essentialFacetValues.current = obj;
	};

	const handleSubmissionOfFacetValues = () => {
		// product_audit_data_type_id,user_id,product_id,primary_identifier,data_value
		const func = (a, b, c, d, e) => {
			axios
				.post('/pa/insertProductAuditDataRecord', {
					product_audit_data_type_id: a,
					user_id: b,
					product_id: c,
					primary_identifier: d,
					data_value: e,
				})
				.then(function (response) {
					console.log('insert response: ', response);
					setOpenSnack(true);
				})
				.catch(function (error) {});
		};

		Object.keys(essentialFacetValues.current).map((fact, idxx) =>
			essentialFacetValues.current[fact] !== null
				? func(
						1,
						user_id,
						parseInt(row.original.product_code),
						parseInt(fact),
						essentialFacetValues.current[fact]
				  )
				: ''
		);
	};

	const handleCloseSnack = () => {
		setOpenSnack(false);
	};

	const handleCloseDeleteSnack = () => {
		setOpenDeleteSnack(false);
	};

	const handleValueDelete = (e, id) => {
		axios
			.post('/pa/deleteMFRAuditData', {
				product_audit_data_id: id,
			})
			.then(function (response) {
				console.log('delete return: ', response.data);
				const func = () => {
					e.target.closest('.MuiBox-root').style.display = 'none';
					e.target.closest('.mfrValue').querySelector('input').value =
						null;
					e.target
						.closest('.mfrValue')
						.querySelector('input').disabled = false;
					e.target
						.closest('.mfrValue')
						.querySelector('.MuiInputBase-root.Mui-disabled')
						.classList.remove('Mui-disabled');
					setOpenDeleteSnack(true);
				};
				response.data.affectedRows === 1 && func();
			})
			.catch(function (error) {});
	};

	// Use Effect
	useEffect(() => {
		const func = () => {
			const obj = {};
			console.log(
				'passed data: ' + user_id + ' | ' + row.original.product_id
			);
			axios
				.get(
					`/pa/getMfrEditedData/${user_id}/${row.original.product_id}/${row.original.product_category_id}`
				)
				.then(function (response) {
					setData(response.data);
					response.data.map(
						(data, idx) => (obj[data.product_facet_id] = null)
					);
					essentialFacetValues.current = { ...obj };
				})
				.catch(function (error) {});
		};
		func();
	}, [row, user_id]);

	console.log('returned dp data: ', data);
	console.log('essentialFacetValues: ', essentialFacetValues);
	return (
		<Box className='detailed_panel'>
			<Typography
				component={'h1'}
				sx={{ color: theme.palette.primary.main }}
			>
				Items Values:
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-evenly',
					gap: 2,
				}}
			>
				{data.map((item, idx) => (
					<Box className='mfrValue'>
						<Box
							display='flex'
							alignItems={'center'}
						>
							<TextField
								className='textFieldMFRValue'
								sx={{
									mb: 0,
									width: 'auto',
									minWidth: '200px',
									maxWidth: '200px',
								}}
								key={idx}
								name={item.product_facet_id}
								label={item.facet}
								disabled={
									item.product_audit_data_id !== 0
										? 'readonly'
										: null
								}
								variant='outlined'
								defaultValue={
									item.data_value ? item.data_value : null
								}
								onChange={(e) => updateEditFacetValues(e)}
								InputLabelProps={{
									shrink: true,
								}}
							/>
							{item.product_audit_data_id !== 0 ? (
								<Box>
									<IconButton
										onClick={(e) =>
											handleValueDelete(
												e,
												item.product_audit_data_id
											)
										}
										color='error'
										sx={{ p: 0 }}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							) : null}
						</Box>
						{item.product_facet_option_id > 0 ? (
							<Typography
								sx={{
									pl: 1,
									fontSize: '12px',
									fontWeight: 'bold',
									color: theme.palette.error.main,
								}}
							>
								SS: {item.facet_value}
							</Typography>
						) : null}
					</Box>
				))}
			</Box>
			<Box
				display='flex'
				justifyContent={'flex-end'}
				sx={{ my: 1 }}
			>
				<Button
					variant='contained'
					onClick={() => handleSubmissionOfFacetValues()}
					color='success'
				>
					Save Data
				</Button>
			</Box>
			<Snackbar
				sx={{ top: '100px !important', textAlign: 'center !important' }}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openSnack}
				onClose={() => handleCloseSnack()}
				autoHideDuration={2500}
			>
				<Alert severity='success'>Data Saved. Thank You!</Alert>
			</Snackbar>

			<Snackbar
				sx={{ top: '100px !important', textAlign: 'center !important' }}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openDeleteSnack}
				onClose={() => handleCloseDeleteSnack()}
				autoHideDuration={2500}
			>
				<Alert severity='error'>Value Deleted. Thank You!</Alert>
			</Snackbar>
		</Box>
	);
};

export default DetailPanelProductDetails;
