import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function PageTitle() {
	const location = useLocation();
	const [pageTitle, setPageTitle] = useState('');

	useEffect(() => {
		switch (location.pathname) {
			case '/calendar':
				setPageTitle('Calendar');
				document.title = 'Calendar';
				break;
			case '/image-library':
				setPageTitle('Image Library');
				document.title = 'Image Library';
				break;
			case '/email-builder':
				setPageTitle('Email Builder');
				document.title = 'Email Builder';
				break;
			case '/crm':
				setPageTitle('Customer Relationship Management');
				document.title = 'CRM';
				break;
			case '/manufacturer-dashboard/product-audit':
				setPageTitle("Manufacturer's Product Audit");
				document.title = 'Product Audit';
				break;
			default:
				setPageTitle('');
				document.title = 'Sports South Hub';
				break;
		}
	}, [location]);

	return pageTitle;
}

export default PageTitle;
