import React, { useContext, useState } from 'react';
import { FaShoppingBag } from 'react-icons/fa';
//import { Context } from '../AdvertisingContext';
//import { ManufacturerContextState } from '../../../ManufacturerContext';
//import AdvertisingCartDetailsContext from '../../../../context/AdvertisingCartDetailsContext';
import { ManufacturerContextState } from './../../../../../../Context/ManufacturerContext';

import { Badge, Drawer, useTheme } from '@mui/material';
//import CartItems from '../Cart/components/CartItems';
import Cart from '../Cart/Cart';

const AdCart = () => {
	const theme = useTheme();
	const { adCartData } = useContext(ManufacturerContextState);

	const [open, setOpen] = useState(false);

	const openDrawer = () => {
		setOpen(true);
	};
	const closeDrawer = () => {
		setOpen(false);
	};

	console.log('adCartDetails: ');
	return (
		<div>
			{adCartData.items.length !== 0 && (
				<>
					<Badge badgeContent={adCartData.items.length} color='primary'>
						<FaShoppingBag
							style={{
								fontSize: '2em',
								color: theme.palette.secondary.main,
								marginTop: '3px',
								cursor: 'pointer',
							}}
							onClick={() => openDrawer()}
						/>
					</Badge>
					<Drawer anchor={'right'} open={open} onClose={() => closeDrawer(false)}>
						<Cart />
					</Drawer>
				</>
			)}
		</div>
	);
};

export default AdCart;
