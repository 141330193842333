import { Box, Button, TextField, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton as MrtFullScreenToggleButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import React from 'react';
import { useTheme } from '@mui/material/styles';

const SettingsTable = () => {
  const theme = useTheme();

  const data = [
    {
      ss_item_number: '18612',
      manufacturer_number: '3848409',
      upc: '589582662843',
      description: '9mm Ammo',
      min_inventory_qty: 1,
      max_inventory_qty: 18,
    },
    {
      ss_item_number: '72915',
      manufacturer_number: '4073556',
      upc: '440297041171',
      description: 'Bolt Action Rifle',
      min_inventory_qty: 8,
      max_inventory_qty: 22,
    },
    {
      ss_item_number: '47632',
      manufacturer_number: '3763816',
      upc: '541151887202',
      description: 'Tactical Rifle Scope',
      min_inventory_qty: 1,
      max_inventory_qty: 25,
    },
    {
      ss_item_number: '81387',
      manufacturer_number: '2500552',
      upc: '366169885782',
      description: '22 LR Rounds',
      min_inventory_qty: 6,
      max_inventory_qty: 24,
    },
    {
      ss_item_number: '85104',
      manufacturer_number: '9266413',
      upc: '594814690751',
      description: 'Bolt Action Rifle',
      min_inventory_qty: 6,
      max_inventory_qty: 25,
    },
    {
      ss_item_number: '41594',
      manufacturer_number: '8087459',
      upc: '527689777534',
      description: '9mm Ammo',
      min_inventory_qty: 9,
      max_inventory_qty: 16,
    },
    {
      ss_item_number: '98829',
      manufacturer_number: '4050500',
      upc: '370870458172',
      description: 'Bolt Action Rifle',
      min_inventory_qty: 8,
      max_inventory_qty: 21,
    },
    {
      ss_item_number: '78312',
      manufacturer_number: '2392823',
      upc: '579200629604',
      description: '22 LR Rounds',
      min_inventory_qty: 3,
      max_inventory_qty: 18,
    },
    {
      ss_item_number: '80252',
      manufacturer_number: '1355465',
      upc: '201365311177',
      description: 'Double-Action Revolver',
      min_inventory_qty: 9,
      max_inventory_qty: 24,
    },
    {
      ss_item_number: '80391',
      manufacturer_number: '5923175',
      upc: '162849959325',
      description: 'Threaded Suppressor',
      min_inventory_qty: 4,
      max_inventory_qty: 24,
    },
    {
      ss_item_number: '12048',
      manufacturer_number: '9514041',
      upc: '355826213314',
      description: 'Extended Magazine',
      min_inventory_qty: 1,
      max_inventory_qty: 28,
    },
    {
      ss_item_number: '22034',
      manufacturer_number: '1998854',
      upc: '411909995032',
      description: 'Extended Magazine',
      min_inventory_qty: 9,
      max_inventory_qty: 15,
    },
    {
      ss_item_number: '22533',
      manufacturer_number: '7817587',
      upc: '452772097261',
      description: 'Gun Cleaning Kit',
      min_inventory_qty: 5,
      max_inventory_qty: 14,
    },
  ];

  const columns = [
    {
      header: 'SS Item #',
      accessorKey: 'ss_item_number',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Mfr #',
      accessorKey: 'manufacturer_number',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'UPC #',
      accessorKey: 'upc',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Description',
      accessorKey: 'description',
      size: 400,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Minimum Qty',
      accessorKey: 'min_inventory_qty',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant='outlined'
            size='small'
            sx={{ width: '100%' }}
            value={renderedCellValue}
          />
        </Box>
      ),
    },
    {
      header: 'Maximum Qty',
      accessorKey: 'max_inventory_qty',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant='outlined'
            size='small'
            sx={{ width: '100%' }}
            value={renderedCellValue}
          />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          initialState={{ showColumnFilters: false, density: 'compact' }}
          positionToolbarAlertBanner='bottom'
          enableStickyHeader
          enableRowActions
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              fontSize: '1rem',
            },
          })}
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box>
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: theme.palette.tsw.tertiary,
                    fontSize: '1rem',
                    fontWeight: 600,
                    whiteSpace: 'pre',
                    mr: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        letterSpacing: '0.05em',
                        fontWeight: 'bold',
                        textTransform: 'none',
                      }}
                    >
                      Import CSV
                    </Typography>
                  </Box>
                </Button>
                <Button
                  variant='contained'
                  sx={{
                    backgroundColor: theme.palette.tsw.tertiary,
                    fontSize: '1rem',
                    fontWeight: 600,
                    whiteSpace: 'pre',
                    mr: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        letterSpacing: '0.05em',
                        fontWeight: 'bold',
                        textTransform: 'none',
                      }}
                    >
                      Add New Item
                    </Typography>
                  </Box>
                </Button>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtFullScreenToggleButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='65%'
                flexDirection='column'
              >
                <Box display='flex' flexDirection='row' width='100%' justifyContent='flex-start'>
                  <Typography
                    variant='h5'
                    sx={{
                      textAlign: 'center',
                      letterSpacing: '0.05em',
                      color: theme.palette.tsw.secondary,
                      lineHeight: 1.2,
                    }}
                  >
                    Edit Mins/Maxes
                  </Typography>
                </Box>
              </Box>
            );
          }}
          renderRowActions={({ row }) => {
            return (
              <Box>
                <Button
                  variant='contained'
                  sx={{
                    color: theme.palette.getContrastText(theme.palette.tsw.tertiary),
                    backgroundColor: theme.palette.tsw.tertiary,
                  }}
                >
                  Edit
                </Button>
              </Box>
            );
          }}
          muiTablePaperProps={{
            sx: {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              height: '100%',
            },
          }}
          muiTableContainerProps={{
            sx: {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflow: 'auto',
            },
          }}
        />
      </Box>
    </>
  );
};

export default SettingsTable;
