import { Box, Typography } from '@mui/material';
import React from 'react';

const ManageUsers = () => {
  return (
    <Box>
      <Typography variant='h4'>Manage Users</Typography>
      <Typography variant='h6'>Coming Soon</Typography>
    </Box>
  );
};

export default ManageUsers;
