import { useEffect, useState } from 'react';
import axios from 'axios';

const MFRData = (id) => {
	//const data1 = useRef({ mfrId: id });
	const [data1, setData1] = useState({ mfrId: id });

	// useEffect(() => {
	// 	console.log('effect step');
	// 	axios.get(`/mlp/getMFRFaceBookYoutube/${id}`).then((response) => {
	// 		data1.current = {
	// 			...data1.current,
	// 			faceBook: response.data[0]?.faceBook ?? 'no facebook',
	// 			youTube: response.data[0]?.youTube ?? 'no youtube',
	// 		};
	// 		console.log('data1: ', data1.current);

	// 		console.log('returned data: ', data1.current.faceBook);
	// 	});
	// }, [id]);

	useEffect(() => {
		const fetchData = async () => {
			const response1 = await axios.get(`/mlp/getMFRFaceBookYoutube/${id}`);

			setData1((state) => ({
				...data1,
				faceBook: response1.data[0]?.faceBook ?? 'no facebook',
				youTube: response1.data[0]?.youTube ?? 'no youtube',
			}));

			// data1.current = {
			// 	...data1.current,
			// 	faceBook: response1.data[0]?.faceBook ?? 'no facebook',
			// 	youTube: response1.data[0]?.youTube ?? 'no youtube',
			// };
			console.log('response1: ', response1.data[0]);
		};

		fetchData(id);
	}, [id]);

	console.log('data2: ', data1.faceBook);

	return (
		<>
			{{
				mfrId: data1.mfrId,
				url: 'https://www.caldwellshooting.com/',
				faceBook: data1.faceBook,
				youTube: data1.youTube,
				categoryData: [
					{
						product_category_id: 283,
						product_category_group_id: 65,
						description: 'Adhesive Targets',
					},
					{
						product_category_id: 135,
						product_category_group_id: 43,
						description: 'Ammo Boxes',
					},
					{
						product_category_id: 55,
						product_category_group_id: 46,
						description: 'Bases',
					},
					{
						product_category_id: 213,
						product_category_group_id: 49,
						description: 'Belly Bands',
					},
					{
						product_category_id: 162,
						product_category_group_id: 46,
						description: 'Bipods',
					},
					{
						product_category_id: 257,
						product_category_group_id: 62,
						description: 'Case Preparation',
					},
					{
						product_category_id: 154,
						product_category_group_id: 2,
						description: 'Chronography',
					},
					{
						product_category_id: 25,
						product_category_group_id: 57,
						description: 'Eye Protection',
					},
					{
						product_category_id: 106,
						product_category_group_id: 1,
						description: 'Gloves',
					},
					{
						product_category_id: 83,
						product_category_group_id: 57,
						description: 'Hearing Protection',
					},
					{
						product_category_id: 75,
						product_category_group_id: 46,
						description: 'Holders And Accessories',
					},
					{
						product_category_id: 178,
						product_category_group_id: 46,
						description: 'Magazine Accessories',
					},
					{
						product_category_id: 176,
						product_category_group_id: 46,
						description: 'Magazine Loaders',
					},
					{
						product_category_id: 282,
						product_category_group_id: 65,
						description: 'Metal Targets',
					},
					{
						product_category_id: 157,
						product_category_group_id: 2,
						description: 'Other Electronics',
					},
					{
						product_category_id: 280,
						product_category_group_id: 65,
						description: 'Paper Targets',
					},
					{
						product_category_id: 173,
						product_category_group_id: 46,
						description: 'Recoil/Butt Pads & Spacers',
					},
					{
						product_category_id: 139,
						product_category_group_id: 43,
						description: 'Rifle Cases',
					},
					{
						product_category_id: 268,
						product_category_group_id: 65,
						description: 'Shooting Benches & Tables',
					},
					{
						product_category_id: 269,
						product_category_group_id: 65,
						description: 'Shooting Rest',
					},
					{
						product_category_id: 51,
						product_category_group_id: 46,
						description: 'Slings',
					},
					{
						product_category_id: 309,
						product_category_group_id: 46,
						description: 'Stock Accessories',
					},
					{
						product_category_id: 163,
						product_category_group_id: 46,
						description: 'Swivel Adaptors',
					},
					{
						product_category_id: 287,
						product_category_group_id: 65,
						description: 'Target Accessories',
					},
					{
						product_category_id: 43,
						product_category_group_id: 65,
						description: 'Traps & Clay Throwers',
					},
					{
						product_category_id: 232,
						product_category_group_id: 55,
						description: 'Tripods',
					},
				],
			}}
		</>
	);
};

export { MFRData };
