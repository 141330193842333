import React from 'react';

import ManufacturerDashboardNavigation from './components/Nav/Nav';
import StaffCards from '../Advertising/components/StaffCards/StaffCards';

const ManufacturerDashboard = () => {
	return (
		<>
			<ManufacturerDashboardNavigation />
			<StaffCards />
		</>
	);
};

export default ManufacturerDashboard;
