import React from 'react';
import { Box, Button, Container, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Facebook, Public, YouTube } from '@mui/icons-material';

import { externalLink } from '../../_GlobalFunctions/ExternalLink';

const HeaderBar = ({ mfrId, faceBook, youTube, url, maxWidth = 'full' }) => {
	const theme = useTheme();
	const screenMedAbove = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<Container
			maxWidth={maxWidth}
			sx={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				p: 1,
				my: { xs: 0, md: 0 },
			}}
		>
			<Button
				onClick={() => externalLink(`https://www.theshootingwarehouse.com/?product_manufacturer_ids=${mfrId}`)}
			>
				<Box
					sx={{ maxWidth: { xs: '80px', md: '145px' } }}
					component='img'
					src={`https://media.server.theshootingwarehouse.com/Logos/small/${mfrId}.jpg`}
					alt='my image'
				/>
			</Button>

			<Box>
				<IconButton onClick={() => externalLink(faceBook)}>
					<Facebook />
				</IconButton>
				<span>|</span>
				<IconButton onClick={() => externalLink(youTube)}>
					<YouTube />
				</IconButton>
				<span>|</span>
				{screenMedAbove ? (
					<Button onClick={() => externalLink(url)}>www.caldwellshooting.com</Button>
				) : (
					<IconButton onClick={() => externalLink(url)}>
						<Public />
					</IconButton>
				)}
			</Box>
		</Container>
	);
};

export default HeaderBar;
