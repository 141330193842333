import React from 'react';
import { Box, styled } from '@mui/material';

// Material Styled Components
const Wrapper = styled(Box)(({ theme }) => ({
	color: theme.palette.primary.main,
	fontWeight: 'bold',
	fontSize: '1.4em',
	lineHeight: '1.4em',
	marginBottom: theme.spacing(5),
}));

const SubText = styled(Box)(({ theme }) => ({
	color: '#666',
	fontWeight: 'bold',
	fontSize: '.7em',
	lineHeight: '.7em',
}));

const TitleSubTitleHeader = ({ text, subtext }) => {
	return (
		<Wrapper>
			<Box>{text}</Box>
			<SubText>{subtext}</SubText>
		</Wrapper>
	);
};

export default TitleSubTitleHeader;
