import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton as MrtFullScreenToggleButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import ReturnReasonModal from './ReturnReasonModal';

const ReturnProcessTable = ({ selectedInvoice }) => {
  const theme = useTheme();
  const [returnReasonModalOpen, setReturnReasonModalOpen] = useState(false);
  const [reasonRows, setReasonRows] = useState(null);

  const handleOpenReason = (reasonRows) => {
    setReasonRows(reasonRows);
    setReturnReasonModalOpen(!returnReasonModalOpen);
  };

  const expandLineItemsUsingFlatMap = (lineItems) => {
    return Object.entries(lineItems).flatMap(([itemId, itemDetails]) => {
      const { UOM, QUANTITY, PRICE, DESCRIPTION, SERIAL_NUMBERS } = itemDetails;
      if (SERIAL_NUMBERS.length === 0)
        return [
          {
            LINE_ITEMS: itemId,
            UOM,
            QUANTITY,
            PRICE,
            DESCRIPTION,
            SERIAL_NUMBERS: '',
          },
        ];
      return SERIAL_NUMBERS.map((serialNumber) => ({
        LINE_ITEMS: itemId,
        UOM,
        QUANTITY: 1,
        PRICE,
        DESCRIPTION,
        SERIAL_NUMBERS: serialNumber,
      }));
    });
  };

  const lineItems = expandLineItemsUsingFlatMap(selectedInvoice.LINE_ITEMS);

  const columns = [
    {
      header: 'Item #',
      accessorKey: 'LINE_ITEMS',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Desc.',
      accessorKey: 'DESCRIPTION',
      size: 200,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Serial #',
      accessorKey: 'SERIAL_NUMBERS',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'UOM',
      accessorKey: 'UOM',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Qty',
      accessorKey: 'QUANTITY',
      size: 75,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Price',
      accessorKey: 'PRICE',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>${renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box>
        {console.log('lineItems:', lineItems)}
        <MaterialReactTable
          columns={columns}
          data={lineItems}
          enableHiding={false}
          enableStickyHeader
          enableRowSelection
          muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
          initialState={{ showColumnFilters: false, density: 'compact' }}
          positionToolbarAlertBanner='bottom'
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              fontSize: '1rem',
            },
          })}
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtFullScreenToggleButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            const handleReason = () => {
              const reasonRows = Object.fromEntries(
                table.getSelectedRowModel().flatRows.map((row) => {
                  return [row.original.LINE_ITEMS, row.original];
                })
              );
              handleOpenReason(reasonRows);
            };
            return (
              <>
                <Box
                  display='flex'
                  alignItems='flex-start'
                  justifyContent='space-between'
                  width='65%'
                  flexDirection='column'
                >
                  <Typography
                    variant='h5'
                    sx={{
                      letterSpacing: '0.05em',
                      color: theme.palette.tsw.secondary,
                      lineHeight: 1.2,
                    }}
                  >
                    Invoice#: {selectedInvoice.INVOICE_NUMBER}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      letterSpacing: '0.05em',
                      color: '#888',
                      lineHeight: 1.2,
                    }}
                  >
                    PO#: {selectedInvoice.PO_NUMBER}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      letterSpacing: '0.05em',
                      color: '#888',
                      lineHeight: 1.2,
                    }}
                  >
                    {dayjs(selectedInvoice.INVOICE_DATE).format('MM/DD/YYYY')}
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    sx={{
                      color: theme.palette.getContrastText(theme.palette.tsw.secondary),
                      backgroundColor: theme.palette.tsw.secondary,
                    }}
                    onClick={handleReason}
                    disabled={table.getSelectedRowModel().rows.length === 0}
                  >
                    <Typography
                      variant='button'
                      sx={{
                        color: theme.palette.getContrastText(theme.palette.tsw.tertiary),
                        textTransform: 'none',
                        letterSpacing: '0.05em',
                        fontSize: '1rem',
                        fontWeight: 600,
                        whiteSpace: 'nowrap',
                      }}
                    >
                      Return Selected Items
                    </Typography>
                  </Button>
                </Box>
              </>
            );
          }}
          muiExpandButtonProps={({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
            sx: {
              color: row.getIsExpanded() ? 'white' : 'gray',
            },
          })}
        />
      </Box>
      <ReturnReasonModal
        open={returnReasonModalOpen}
        onClose={handleOpenReason}
        selectedItems={reasonRows}
      />
    </>
  );
};

export default ReturnProcessTable;
