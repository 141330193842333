import React from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Rebate from './components/Rebate';
import Caption from '../Caption';

const Rebates = () => {
	return (
		<Container maxWidth='xl' sx={{ my: 4 }}>
			<Caption caption='Rebates and Promotions:' />
			<Grid
				container
				justifyContent={{ xs: 'center' }}
				flexDirection={{ xs: 'column', sm: 'row' }}
				spacing={{ xs: 2, md: 2 }}
				sx={{}}
			>
				{[1, 2, 3, 4].map((e) => (
					<Grid sx={{}} xs={12} sm={6} lg={3}>
						<Rebate bannerId={e} />
					</Grid>
				))}
			</Grid>
		</Container>
	);
};

export default Rebates;
