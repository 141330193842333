import React from 'react';

import { Box, useTheme } from '@mui/material';
const Caption = ({ caption, color }) => {
	const theme = useTheme();
	return (
		<Box sx={{ color: color ? color : theme.palette.primary.main, fontSize: '20px', fontWeight: 'bold', mb: 1 }}>
			{caption}
		</Box>
	);
};

export default Caption;
