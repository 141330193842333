import React, { useEffect, useState } from 'react';
import { HornadyFeed } from './assets/HornadyFeed';

const MainComponent = () => {
	// const [data, setData] = useState([]);

	// useEffect(() => {
	// 	const allAttr = HornadyFeed.flatMap((obj) => Object.keys(obj));
	// 	const uniqueValues = [...new Set(allAttr)];
	// 	setData([...uniqueValues]);
	// }, []);
	// console.log('abc: ', data);

	const returnArray = () => {
		const allAttr = HornadyFeed.flatMap((obj) => Object.keys(obj));
		const uniqueAttr = [...new Set(allAttr)];
		return uniqueAttr.sort();
	};
	return returnArray();
};

export default MainComponent;
