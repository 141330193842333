import React from 'react';
import { Box, Button, Container, Divider, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { externalLink } from '../../../_GlobalFunctions/ExternalLink';
import CategoryDropDownMenu from '../CategoryDropDownMenu';
import { AccessAlarm, LocationSearching, Search } from '@mui/icons-material';

const ButtonSearchGroup = ({ brandData }) => {
	console.log('bsg: ', brandData);
	const theme = useTheme();
	return (
		<React.Fragment>
			<Container maxWidth='sm' sx={{ transform: { lg: 'translateY(-40%)' }, my: { xs: 3, lg: 0 } }}>
				<Grid container justifyContent={'center'} alignItems={'center'} spacing={{ xs: 0, sm: 3, lg: 0 }}>
					<Grid xs={12} sm={6}>
						{/* <Grid
							container
							xs={12}
							sx={{
								backgroundColor: 'white',
								position: 'relative',
								boxShadow: 1,
								mb: { xs: 3, sm: 0 },
								px: 1,
								py: 2,
								cursor: 'pointer',
								transition: '300ms ease-in-out',
								'&:hover': { backgroundColor: '#f4f4f4' },
							}}
						>
							<Box sx={{ width: '55px', display: 'flex' }}>
								<AccessAlarm sx={{ m: 'auto', fontSize: '2.5em', color: theme.palette.primary.main }} />
							</Box>
							<Box sx={{ flex: 1, pr: 1 }}>
								<Typography sx={{ fontWeight: 'Bold' }}>Search Categories</Typography>
								<Divider sx={{ my: 0.5 }} />
								<Typography sx={{ fontSize: '.9em' }}>Search all categories</Typography>
							</Box>
							<Box
								sx={{
									position: 'absolute',
									top: '100%',
									right: '20px',
									transform: 'translateY(-50%)',
									borderRadius: '50%',
									backgroundColor: 'white',
									width: '35px',
									height: '35px',
									display: 'flex',
									border: 'solid 1px silver',
								}}
							>
								<Search sx={{ m: 'auto', color: theme.palette.primary.main }} />
							</Box>
						</Grid> */}
						<CategoryDropDownMenu brandData={brandData} />
					</Grid>
					{/* */}
					<Grid xs={12} sm={6}>
						<Grid
							container
							xs={12}
							sx={{
								backgroundColor: 'white',
								position: 'relative',
								boxShadow: 1,
								mb: { xs: 3, sm: 0 },
								px: 1,
								py: 2,
								cursor: 'pointer',
								transition: '300ms ease-in-out',
								'&:hover': { backgroundColor: '#f4f4f4' },
							}}
							onClick={() =>
								externalLink(
									`https://www.theshootingwarehouse.com/?product_manufacturer_ids=${brandData.mfrId}`
								)
							}
						>
							<Box sx={{ width: '55px', display: 'flex' }}>
								<AccessAlarm sx={{ m: 'auto', fontSize: '2.5em', color: theme.palette.primary.main }} />
							</Box>
							<Box sx={{ flex: 1, pr: 1 }}>
								<Typography sx={{ fontWeight: 'Bold' }}>View All Items</Typography>
								<Divider sx={{ my: 0.5 }} />
								<Typography sx={{ fontSize: '.9em' }}>View all brand's items</Typography>
							</Box>
							<Box
								sx={{
									position: 'absolute',
									top: '100%',
									right: '20px',
									transform: 'translateY(-50%)',
									borderRadius: '50%',
									backgroundColor: 'white',
									width: '35px',
									height: '35px',
									display: 'flex',
									border: 'solid 1px silver',
								}}
							>
								<Search sx={{ m: 'auto', color: theme.palette.primary.main }} />
							</Box>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	);
};

export default ButtonSearchGroup;
