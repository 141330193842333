import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Manufactuers from '../Components/Manufacturers/Manufactuers';
import HornadyPIM from '../Components/HornadyPIM/HornadyPIM';
import HornadyPIMItem from '../Components/HornadyPIM/HornadyPIMItem';
import ManufacturerDashboard from '../Components/Manufacturers/components/ManufacturerDashboard/ManufacturerDashboard';
import ProductAudit from '../Components/Manufacturers/components/ProductAudit/ProductAudit';
import AdvertisingRoute from './AdvertisingRoute';

const ManufacturerRoute = () => {
	return (
		<Manufactuers>
			<Routes>
				<Route path='/audit/*' element={<ProductAudit />} />
				<Route path='/advertising/*' element={<AdvertisingRoute />} />
				<Route path='/*' element={<ManufacturerDashboard />} />
			</Routes>
		</Manufactuers>
	);
};

export default ManufacturerRoute;
