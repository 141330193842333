import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton as MrtFullScreenToggleButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import ReturnProcessModal from './ReturnProcessModal';

const NewReturnTable = () => {
  const theme = useTheme();
  const [returnProcessModalOpen, setReturnProcessModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleReturnProcessModalOpen = (row) => {
    setSelectedInvoice(row.original);
    setReturnProcessModalOpen(!returnProcessModalOpen);
  };

  const data = [
    {
      INVOICE_NUMBER: '19223725',
      PO_NUMBER: '678-AP',
      INVOICE_DATE: '2024-04-08',
      INVOICE_NET_TOTAL: '10788.69',
      LINE_ITEMS: {
        100054: {
          UOM: 'EA',
          QUANTITY: '14',
          PRICE: '29.99',
          DESCRIPTION: 'LEU 182657 PRO GEAR STRETCH ZIP POUCH S',
          SERIAL_NUMBERS: [],
        },
        141149: {
          UOM: 'GN',
          QUANTITY: '3',
          PRICE: '802.03',
          DESCRIPTION: 'DIA DB1018C071  308  CARB      16 2OR        MDBRZ',
          SERIAL_NUMBERS: ['DB-7032738', 'DB-7032742', 'DB-7032750'],
        },
        141187: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '469.97',
          DESCRIPTION: 'DIA DB175AB001  300BO  ML      16 30R          BLK',
          SERIAL_NUMBERS: ['DB2673738'],
        },
        141147: {
          UOM: 'GN',
          QUANTITY: '2',
          PRICE: '760.32',
          DESCRIPTION: 'DIA DB1018C001  308  DB10 ML   16 20R      CRB/BLK',
          SERIAL_NUMBERS: ['DB-7039533', 'DB-7039541'],
        },
        153896: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '588.16',
          DESCRIPTION: 'SAV 67002 STANCE MC9MS BLK NS 9MM 3.2 7/8',
          SERIAL_NUMBERS: ['DB2678465'],
        },
        141137: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '560.35',
          DESCRIPTION: 'DIA DB1717K061  223  DB15 ML   12 30R      CRB/FDE',
          SERIAL_NUMBERS: ['DB2948760'],
        },
        141186: {
          UOM: 'EA',
          QUANTITY: '1',
          PRICE: '519.00',
          DESCRIPTION: 'WPI   ACCURATE LT301   LT-30  RFL POWDER   1LB',
          SERIAL_NUMBERS: [],
        },
      },
    },
    {
      INVOICE_NUMBER: '19324526',
      PO_NUMBER: '889-CP',
      INVOICE_DATE: '2024-04-15',
      INVOICE_NET_TOTAL: '9550.42',
      LINE_ITEMS: {
        100064: {
          UOM: 'EA',
          QUANTITY: '20',
          PRICE: '22.50',
          DESCRIPTION: 'XTG 184579 ULTRA SLIM HARD SHELL CASE M',
          SERIAL_NUMBERS: [],
        },
        141259: {
          UOM: 'GN',
          QUANTITY: '3',
          PRICE: '825.99',
          DESCRIPTION: 'DIA DB1019C045  350  LEGEND      18 2OR        GRN',
          SERIAL_NUMBERS: ['DB-8032839', 'DB-8032844', 'DB-8032856'],
        },
        141287: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '485.75',
          DESCRIPTION: 'DIA DB1765AB002  450BM  BUSHMASTER  16 30R          BLK',
          SERIAL_NUMBERS: ['DB3779738'],
        },
        141257: {
          UOM: 'GN',
          QUANTITY: '2',
          PRICE: '779.12',
          DESCRIPTION: 'DIA DB1020C002  358  DB11 ML   16 20R      CRB/BLK',
          SERIAL_NUMBERS: ['DB-8037533', 'DB-8037541'],
        },
        153796: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '608.24',
          DESCRIPTION: 'SAV 67102 STANCE MC9MS SIL NS 9MM 3.5 7/8',
          SERIAL_NUMBERS: ['DB3778465'],
        },
        141137: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '590.40',
          DESCRIPTION: 'DIA DB1720K061  223  DB16 ML   14 30R      CRB/FDE',
          SERIAL_NUMBERS: ['DB3948760'],
        },
        141286: {
          UOM: 'EA',
          QUANTITY: '1',
          PRICE: '529.00',
          DESCRIPTION: 'WPI   ACCURATE LT302   LT-31  RFL POWDER   2LB',
          SERIAL_NUMBERS: [],
        },
        199421: {
          UOM: 'EA',
          QUANTITY: '5',
          PRICE: '148.75',
          DESCRIPTION: 'TEC 204578 TACTICAL OPS DRONE',
          SERIAL_NUMBERS: [],
        },
      },
    },
    {
      INVOICE_NUMBER: '19425327',
      PO_NUMBER: '790-DM',
      INVOICE_DATE: '2024-04-22',
      INVOICE_NET_TOTAL: '10299.88',
      LINE_ITEMS: {
        100074: {
          UOM: 'EA',
          QUANTITY: '15',
          PRICE: '33.80',
          DESCRIPTION: 'MLP 190678 PRO TECH GEAR POUCH XL',
          SERIAL_NUMBERS: [],
        },
        141369: {
          UOM: 'GN',
          QUANTITY: '4',
          PRICE: '840.15',
          DESCRIPTION: 'DIA DB1022C091  375  SNIPER      20 2OR        BLU',
          SERIAL_NUMBERS: ['DB-9032738', 'DB-9032742', 'DB-9032750', 'DB-9032754'],
        },
        141387: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '499.99',
          DESCRIPTION: 'DIA DB1775AB011  450BM  RANGER     16 30R          GRY',
          SERIAL_NUMBERS: ['DB4773738'],
        },
        141347: {
          UOM: 'GN',
          QUANTITY: '2',
          PRICE: '785.32',
          DESCRIPTION: 'DIA DB1023C003  358  DB12 ML   18 20R      CRB/BLK',
          SERIAL_NUMBERS: ['DB-9039533', 'DB-9039541'],
        },
        153896: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '625.50',
          DESCRIPTION: 'SAV 67203 STANCE MC10MS GRN NS 9MM 3.8 8/9',
          SERIAL_NUMBERS: ['DB4778465'],
        },
        141147: {
          UOM: 'GN',
          QUANTITY: '1',
          PRICE: '570.25',
          DESCRIPTION: 'DIA DB1723K062  223  DB17 ML   15 30R      CRB/BLU',
          SERIAL_NUMBERS: ['DB4948760'],
        },
      },
    },
  ];

  const columns = [
    {
      header: 'Invoice #',
      accessorKey: 'INVOICE_NUMBER',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'PO #',
      accessorKey: 'PO_NUMBER',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: '# of Lines',
      id: 'LINE_ITEMS',
      accessorFn: (row) => Object.keys(row['LINE_ITEMS']).length,
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Invoice Date',
      accessorKey: 'INVOICE_DATE',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{dayjs(renderedCellValue).format('MM/DD/YYYY')}</Typography>
        </Box>
      ),
    },
    {
      header: 'Invoice Net Total',
      accessorKey: 'INVOICE_NET_TOTAL',
      size: 100,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>${renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          enableExpandAll={false}
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
          initialState={{ showColumnFilters: false }}
          positionToolbarAlertBanner='bottom'
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              fontSize: '1rem',
            },
          })}
          muiDetailPanelProps={({ row }) => ({
            sx: {
              borderLeft: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderRight: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderBottom: row.getIsExpanded()
                ? `2px solid ${theme.palette.tsw.tertiary}`
                : 'none',
            },
          })}
          muiTableBodyCellProps={({ row }) => ({
            sx: {
              borderTop: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderBottom: row.getIsExpanded()
                ? `2px solid ${theme.palette.tsw.tertiary}`
                : 'none',
              backgroundColor: row.getIsExpanded() ? theme.palette.tsw.tertiary : 'white',
              color: row.getIsExpanded() ? 'white' : 'black',
              '&:last-child': {
                borderRight: row.getIsExpanded()
                  ? `2px solid ${theme.palette.tsw.tertiary}`
                  : 'none',
              },
              '&:first-child': {
                borderLeft: row.getIsExpanded()
                  ? `2px solid ${theme.palette.tsw.tertiary}`
                  : 'none',
              },
            },
          })}
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtFullScreenToggleButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box
                display='flex'
                alignItems='flex-start'
                justifyContent='space-between'
                width='65%'
                flexDirection='column'
              >
                <Typography
                  variant='h5'
                  sx={{
                    letterSpacing: '0.05em',
                    color: theme.palette.tsw.secondary,
                    lineHeight: 1.2,
                  }}
                >
                  Eligible Invoices
                </Typography>
                <Typography
                  variant='subtitle1'
                  sx={{
                    letterSpacing: '0.05em',
                    color: '#888',
                    lineHeight: 1.2,
                  }}
                >
                  Invoices from past 21 days
                </Typography>
              </Box>
            );
          }}
          renderDetailPanel={({ row }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} container textAlign='end'>
                    <Grid item xs={12} sx={{ pb: 1, mt: -2 }}>
                      <Button
                        variant='contained'
                        sx={{
                          color: theme.palette.getContrastText(theme.palette.tsw.secondary),
                          backgroundColor: theme.palette.tsw.secondary,
                        }}
                        onClick={() => handleReturnProcessModalOpen(row)}
                      >
                        <Typography
                          variant='button'
                          sx={{
                            color: theme.palette.getContrastText(theme.palette.tsw.tertiary),
                            textTransform: 'none',
                            letterSpacing: '0.05em',
                            fontSize: '1rem',
                            fontWeight: 600,
                          }}
                        >
                          Begin Return Process
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-start'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Item #
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} container justifyContent='flex-start'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Description
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Serial #
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Unit of Measure
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Quantity
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Price
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider width='100%' />
                  {Object.entries(row.original['LINE_ITEMS']).map(([key, value]) => {
                    return (
                      <Grid container spacing={2} key={key}>
                        <Grid item xs={1} container justifyContent='flex-start'>
                          <Box display='inline-block' sx={{ width: '100%' }}>
                            <Typography variant='body1'>{key}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} container justifyContent='flex-start'>
                          <Box display='inline-block' sx={{ width: '100%' }}>
                            <Typography variant='body1'>{value.DESCRIPTION}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>
                              {value.SERIAL_NUMBERS.length > 1
                                ? 'Multiple'
                                : value.SERIAL_NUMBERS[0]}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>{value.UOM}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>{value.QUANTITY}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>${value.PRICE}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            );
          }}
          muiExpandButtonProps={({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
            sx: {
              color: row.getIsExpanded() ? 'white' : 'gray',
            },
          })}
        />
      </Box>
      <ReturnProcessModal
        open={returnProcessModalOpen}
        onClose={handleReturnProcessModalOpen}
        selectedInvoice={selectedInvoice}
      />
    </>
  );
};

export default NewReturnTable;
