import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const SlideWrapper = styled(Box)(({ theme }) => ({
	padding: theme.spacing(3),
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	backgroundPosition: 'center center',
	position: 'relative',
	'& > *': {
		position: 'relative',
		zIndex: 10,
		color: 'white',
	},
	'& .backgroundImage': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		display: 'block',
		objectFit: 'cover',
		objectPosition: 'center center',
		zIndex: 0,
	},
}));

const Slide = ({ backgroundOpacity, backgroundColor = 'red', children, imgURL, bannerNavURL = false }) => {
	const handleOnBannerClick = (bannerNavURL) => {
		console.log('bannerNavURL: ' + bannerNavURL);
		window.location.href = bannerNavURL;
	};
	return (
		<SlideWrapper
			sx={{ backgroundColor: backgroundColor, cursor: bannerNavURL ? 'pointer' : 'auto' }}
			onClick={() => (bannerNavURL ? handleOnBannerClick(bannerNavURL) : console.log('Banner Clicked'))}
		>
			<Box>{children}</Box>
			{imgURL && (
				<img
					src={imgURL}
					style={{ opacity: backgroundOpacity }}
					className='backgroundImage'
					alt={`background for `}
				/>
			)}
		</SlideWrapper>
	);
};

export default Slide;
