import React, { useContext, useEffect, useRef, useState } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dayjs from 'dayjs';

import { ManufacturerContextState } from './../../../../../../Context/ManufacturerContext';

import {
	Box,
	Button,
	Checkbox,
	Container,
	FormControl,
	FormControlLabel,
	MenuItem,
	Modal,
	Switch,
	TextField,
	Typography,
} from '@mui/material';

const today = Dayjs().format('YYYY-MM-DD');
const nextYear = Dayjs().add(1, 'year').format('YYYY-MM-DD');

const CoopFundForm = ({ fundId }) => {
	const { modalOpen, setModalOpen } = useContext(ManufacturerContextState);
	const [fundTypeLabel, setFundTypeLabel] = useState('');

	const fundIdValue = useRef('');

	const [formManufacturer, setFormManufacturer] = useState(0);
	const [formCoopType, setFormCoopType] = useState(0);
	const [formOrigAgent, setFormOrigAgent] = useState(0);
	const [formDescription, setFormDescription] = useState('');
	const [formNotes, setFormNotes] = useState('');
	const [formPercentAmount, setFormPercentAmount] = useState(0);
	const [formFixedAmount, setFormFixedAmount] = useState(0);
	const [formRequiredAmount, setFormRequiredAmount] = useState(0);
	const [formAccumulatedAmount, setFormAccumulatedAmount] = useState(0);
	const [formBillable, setFormBillable] = useState(false);
	const [formRequiredAmountStartDate, setFormRequiredAmountStartDate] = useState(Dayjs(today));
	const [formRequiredAmountEndDate, setFormRequiredAmountEndDate] = useState(Dayjs(nextYear));
	const [formRequiredUsageStartDate, setFormRequiredUsageStartDate] = useState(Dayjs(today));
	const [formRequiredUsageEndDate, setFormRequiredUsageEndDate] = useState(Dayjs(nextYear));
	const [formSpecificChannel, setFormSpecificChannel] = useState(0);
	const [formSpecificArticle, setFormSpecificArticle] = useState(0);
	const [formRollOver, setFormRollOver] = useState(false);
	const [formRemainingBalance, setFormRemainingBalance] = useState(0);
	const [formEarnedAmount, setFormEarnedAmount] = useState(0);
	const [formMarketingCompleted, setFormMarketingCompleted] = useState(false);
	const [formArtProduct, setFormArtProduct] = useState(0);

	const handleSubmitButtonClick = () => {
		const string = `
		${formManufacturer} |  
		${formCoopType} | 
		${formOrigAgent} | 
		${formDescription} | 
		${formNotes} | 
		${formPercentAmount} | 
		${formFixedAmount} | 
		${formRequiredAmount} | 
		${formAccumulatedAmount} | 
		${formBillable} | 
		${formRequiredAmountStartDate} | 
		${formRequiredAmountEndDate} | 
		${formRequiredUsageStartDate} | 
		${formRequiredUsageEndDate} | 
		${formSpecificChannel} | 
		${formRollOver} | 
		${formRemainingBalance} | 
		${formEarnedAmount} | 
		${formMarketingCompleted} | 
		${formArtProduct} | 
		`;
		alert(string);
	};

	const handleFormElementChange = (value, stateName) => {
		console.log('value: ' + value + ' name: ' + stateName);
		setFormManufacturer(value);
	};

	useEffect(() => {
		console.log('formmfr: ' + formManufacturer);
	}, [formManufacturer]);

	useEffect(() => {
		setModalOpen(true);
	}, [setModalOpen]);

	useEffect(() => {
		switch (fundId) {
			case 'co-op':
				setFundTypeLabel('% Percentage Amount');
				break;

			case 'fixed-dollar':
				setFundTypeLabel('$ Fixed Dollar Amount');
				break;
			default:
				setFundTypeLabel('% Percentage Amount');
		}
	}, [fundId, setFundTypeLabel]);

	return (
		<React.Fragment>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: 'repeat(12,1fr)',
					gridTemplateRows: 'auto',
					gridTemplateAreas: `
      
						"mfr mfr mfr mfr . percentAmount percentAmount percentAmount . reqDollarAmount reqDollarAmount reqDollarAmount"
						"amtStartDate amtStartDate amtStartDate amtStartDate amtStartDate amtStartDate amtEndDate amtEndDate amtEndDate amtEndDate amtEndDate amtEndDate"
						"useStartDate useStartDate useStartDate useStartDate useStartDate useStartDate useEndDate useEndDate useEndDate useEndDate useEndDate useEndDate"
						"desc desc desc desc desc desc desc desc desc desc desc desc"
						"notes notes notes notes notes notes notes notes notes notes notes notes"
						". . . . . . . . . submitBtn submitBtn submitBtn"
					`,
					gap: 3,
				}}
			>
				<FormControl sx={{ gridArea: 'percentAmount' }}>
					<TextField
						label={fundTypeLabel}
						value={formPercentAmount}
						onChange={(e) => setFormPercentAmount(e.target.value)}
					/>
				</FormControl>
				<FormControl sx={{ gridArea: 'mfr' }}>
					<TextField
						select
						label='Manufacturer'
						value={formManufacturer}
						onChange={(e) => handleFormElementChange(e.target.value, 'setFormManufacturer')}
					>
						<MenuItem value={0}> </MenuItem>
						<MenuItem value={451}>1791 Leather</MenuItem>
						<MenuItem value={2205}>AccuSharp</MenuItem>
						<MenuItem value={525}>Agulia Ammunition</MenuItem>
					</TextField>
				</FormControl>

				{/* <FormControl sx={{ gridArea: 'b' }}>
					<TextField
						select
						label='Co-op Type'
						value={formCoopType}
						onChange={(e) => setFormCoopType(e.target.value)}
					>
						<MenuItem value={0}> </MenuItem>
						<MenuItem value={1}>PV (Preferred Vendor)</MenuItem>
						<MenuItem value={2}>Buyer Agent</MenuItem>
						<MenuItem value={3}>Contract</MenuItem>
					</TextField>
				</FormControl> */}

				{/* <FormControl sx={{ gridArea: 'c' }}>
						<TextField
							select
							label='Originating Agent'
							value={formOrigAgent}
							onChange={(e) => setFormOrigAgent(e.target.value)}
						>
							<MenuItem value={0}> </MenuItem>
							<MenuItem value={1}>Harry Spotts | Proactive Sales</MenuItem>
							<MenuItem value={2}>Caleb Sutton | SS Buyer</MenuItem>
							<MenuItem value={3}>Athena Walls | SS Marketing</MenuItem>
						</TextField>
					</FormControl> */}

				{/* <FormControl sx={{ gridArea: 'd' }}>
					<TextField
						label='Percent Amount'
						value={formPercentAmount}
						onChange={(e) => setFormPercentAmount(e.target.value)}
					/>
				</FormControl> */}

				{/* <FormControl sx={{ gridArea: 'e' }}>
					<TextField
						label='Fixed Amount'
						value={formFixedAmount}
						onChange={(e) => setFormFixedAmount(e.target.value)}
					/>
				</FormControl>

				<FormControl sx={{ gridArea: 'ea' }}>
					<TextField
						label='Earned Amount'
						value={formEarnedAmount}
						onChange={(e) => setFormEarnedAmount(e.target.value)}
					/>
				</FormControl>

				<FormControl sx={{ gridArea: 'rb' }}>
					<TextField
						label='Remaining Balance'
						value={formRemainingBalance}
						onChange={(e) => setFormRemainingBalance(e.target.value)}
					/>
				</FormControl> */}

				{/* <FormControl sx={{ gridArea: 'f' }}>
					<FormControlLabel
						label='Billable?'
						labelPlacement='end'
						control={
							<Checkbox checked={formBillable} onChange={(e) => setFormBillable(e.target.checked)} />
						}
					/>
				</FormControl> */}

				<FormControl sx={{ gridArea: 'reqDollarAmount' }}>
					<TextField
						label='Required Dollar Amount'
						value={formRequiredAmount}
						onChange={(e) => setFormRequiredAmount(e.target.value)}
					/>
				</FormControl>

				{/* <FormControl sx={{ gridArea: 'h' }}>
					<TextField
						label='Accumulated Dollar Amount'
						value={formAccumulatedAmount}
						onChange={(e) => setFormAccumulatedAmount(e.target.value)}
					/>
				</FormControl> */}

				<FormControl sx={{ gridArea: 'amtStartDate' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={formRequiredAmountStartDate}
							onChange={(e) => setFormRequiredAmountStartDate(e)}
							label='Required Amount Start Date'
						/>
					</LocalizationProvider>
				</FormControl>

				<FormControl sx={{ gridArea: 'amtEndDate' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={formRequiredAmountEndDate}
							onChange={(e) => setFormRequiredAmountEndDate(e)}
							label='Required Amount End Date'
						/>
					</LocalizationProvider>
				</FormControl>

				<FormControl sx={{ gridArea: 'useStartDate' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={formRequiredUsageStartDate}
							onChange={(e) => setFormRequiredUsageStartDate(e)}
							label='Required Usage Start Date'
						/>
					</LocalizationProvider>
				</FormControl>

				<FormControl sx={{ gridArea: 'useEndDate' }}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							value={formRequiredUsageEndDate}
							onChange={(e) => setFormRequiredUsageEndDate(e)}
							label='Required Usage End Date'
						/>
					</LocalizationProvider>
				</FormControl>

				{/* <FormControl sx={{ gridArea: 'm' }}>
					<FormControlLabel
						label='Rolls Over?'
						labelPlacement='end'
						control={
							<Checkbox checked={formRollOver} onChange={(e) => setFormRollOver(e.target.checked)} />
						}
					/>
				</FormControl>

				<FormControl sx={{ gridArea: 'n' }}>
					<TextField
						select
						label='Specific Channel'
						value={formSpecificChannel}
						onChange={(e) => setFormSpecificChannel(e.target.value)}
					>
						<MenuItem value={0}>Any Channels</MenuItem>
						<MenuItem value={1}>Mailer</MenuItem>
						<MenuItem value={2}>Package Stuffer</MenuItem>
						<MenuItem value={3}>Home Page Banner</MenuItem>
					</TextField>
				</FormControl>

				<FormControl sx={{ gridArea: 'o' }}>
					<TextField
						select
						label='Specific Article'
						value={formSpecificArticle}
						onChange={(e) => setFormSpecificArticle(e.target.value)}
					>
						<MenuItem value={0}>Any Article</MenuItem>
						<MenuItem value={1}>Turkey Flyer</MenuItem>
						<MenuItem value={2}>May Stuffer</MenuItem>
						<MenuItem value={3}>Rimfire Flyer</MenuItem>
					</TextField>
				</FormControl>

				<FormControl sx={{ gridArea: 'artProduct' }}>
					<TextField
						select
						label='Article Product'
						value={formArtProduct}
						onChange={(e) => setFormArtProduct(e.target.value)}
					>
						<MenuItem value={0}>Any Layout</MenuItem>
						<MenuItem value={1}>Single Page</MenuItem>
						<MenuItem value={2}>2 Page Spread</MenuItem>
						<MenuItem value={3}>Strip Ad</MenuItem>
					</TextField>
				</FormControl> */}

				<FormControl sx={{ gridArea: 'desc' }}>
					<TextField
						label='Description'
						value={formDescription}
						onChange={(e) => setFormDescription(e.target.value)}
					/>
				</FormControl>

				<FormControl sx={{ gridArea: 'notes' }}>
					<TextField
						label='Notes'
						multiline
						rows={3}
						value={formNotes}
						onChange={(e) => setFormNotes(e.target.value)}
					/>
				</FormControl>

				{/* <FormControl sx={{ gridArea: 'mktcomplete' }}>
					<FormControlLabel
						label='Marketing Completed?'
						labelPlacement='end'
						control={
							<Checkbox
								checked={formMarketingCompleted}
								onChange={(e) => setFormMarketingCompleted(e.target.checked)}
							/>
						}
					/>
				</FormControl> */}

				<FormControl sx={{ gridArea: 'submitBtn' }}>
					<Button
						variant='contained'
						color='success'
						onClick={() => {
							handleSubmitButtonClick();
						}}
					>
						Create Co-op
					</Button>
				</FormControl>
			</Box>
		</React.Fragment>
	);
};

export default CoopFundForm;
