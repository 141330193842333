import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleFullScreenButton as MrtFullScreenToggleButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import NewReturnModal from './NewReturnModal';
import dayjs from 'dayjs';

const ReturnTable = () => {
  const theme = useTheme();
  const [newReturnModalOpen, setNewReturnModalOpen] = useState(false);

  const handleNewReturnModal = () => {
    setNewReturnModalOpen(!newReturnModalOpen);
  };

  const data = [
    {
      rma: 'RMA-1090922',
      invoice: '23561239',
      orderDate: '03-24-2024',
      returnDate: '03-28-2024',
      returnStatus: 'Started',
      notes: '',
    },
    {
      rma: 'RMA-7684930',
      invoice: '35678965',
      orderDate: '03-24-2024',
      returnDate: '03-29-2024',
      returnStatus: 'Started',
      notes: '',
    },
    {
      rma: 'RMA-5538829',
      invoice: '44590292',
      orderDate: '03-26-2024',
      returnDate: '03-30-2024',
      returnStatus: 'Received',
      notes: '',
    },
    {
      rma: 'RMA-2679566',
      invoice: '65257835',
      orderDate: '03-26-2024',
      returnDate: '03-31-2024',
      returnStatus: 'Accepted',
      notes: '',
    },
    {
      rma: 'RMA-1199258',
      invoice: '98723456',
      orderDate: '03-27-2024',
      returnDate: '04-01-2024',
      returnStatus: 'Denied',
      notes: 'Product was damaged',
    },
    {
      rma: 'RMA-9887634',
      invoice: '64362210',
      orderDate: '04-01-2024',
      returnDate: '04-06-2024',
      returnStatus: 'Denied',
      notes: 'Wrong product returned',
    },
  ];

  const innerData = {
    100054: {
      UOM: 'EA',
      QUANTITY: '14',
      PRICE: '29.99',
      DESCRIPTION: 'LEU 182657 PRO GEAR STRETCH ZIP POUCH S',
      SERIAL_NUMBERS: [],
      REASON: 'Wrong product ordered',
    },
    141149: {
      UOM: 'GN',
      QUANTITY: '3',
      PRICE: '802.03',
      DESCRIPTION: 'DIA DB1018C071  308  CARB      16 2OR        MDBRZ',
      SERIAL_NUMBERS: ['DB-7032738', 'DB-7032742', 'DB-7032750'],
      REASON: 'Barrel was scratched',
    },
    141187: {
      UOM: 'GN',
      QUANTITY: '1',
      PRICE: '469.97',
      DESCRIPTION: 'DIA DB175AB001  300BO  ML      16 30R          BLK',
      SERIAL_NUMBERS: ['DB2673738'],
      REASON: 'Gun was full of peanut butter',
    },
    141147: {
      UOM: 'GN',
      QUANTITY: '2',
      PRICE: '760.32',
      DESCRIPTION: 'DIA DB1018C001  308  DB10 ML   16 20R      CRB/BLK',
      SERIAL_NUMBERS: ['DB-7039533', 'DB-7039541'],
      REASON: 'Bang bang too loud',
    },
    153896: {
      UOM: 'GN',
      QUANTITY: '1',
      PRICE: '588.16',
      DESCRIPTION: 'SAV 67002 STANCE MC9MS BLK NS 9MM 3.2 7/8',
      SERIAL_NUMBERS: ['DB2678465'],
      REASON: 'Not what I ordered',
    },
    141137: {
      UOM: 'GN',
      QUANTITY: '1',
      PRICE: '560.35',
      DESCRIPTION: 'DIA DB1717K061  223  DB15 ML   12 30R      CRB/FDE',
      SERIAL_NUMBERS: ['DB2948760'],
      REASON: "Wrong photo, must be Cindy's fault",
    },
    141186: {
      UOM: 'EA',
      QUANTITY: '1',
      PRICE: '519.00',
      DESCRIPTION: 'WPI   ACCURATE LT301   LT-30  RFL POWDER   1LB',
      SERIAL_NUMBERS: [],
      REASON: 'Because I can',
    },
  };

  const columns = [
    {
      header: 'RMA #',
      accessorKey: 'rma',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Original Invoice #',
      accessorKey: 'invoice',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Order Date',
      accessorKey: 'orderDate',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{dayjs(renderedCellValue).format('MM/DD/YYYY')}</Typography>
        </Box>
      ),
    },
    {
      header: 'Date Started',
      accessorKey: 'returnDate',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{dayjs(renderedCellValue).format('MM/DD/YYYY')}</Typography>
        </Box>
      ),
    },
    {
      header: 'Return Status',
      accessorKey: 'returnStatus',
      size: 150,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
    {
      header: 'Notes',
      accessorKey: 'notes',
      size: 400,
      Cell: ({ renderedCellValue }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant='body1'>{renderedCellValue}</Typography>
        </Box>
      ),
    },
  ];

  return (
    <>
      <Box>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          initialState={{ showColumnFilters: false }}
          positionToolbarAlertBanner='bottom'
          enableStickyHeader
          muiTableContainerProps={{ sx: { maxHeight: '63vh' } }}
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              fontSize: '1rem',
            },
          })}
          muiDetailPanelProps={({ row }) => ({
            sx: {
              borderLeft: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderRight: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderBottom: row.getIsExpanded()
                ? `2px solid ${theme.palette.tsw.tertiary}`
                : 'none',
            },
          })}
          muiTableBodyCellProps={({ row }) => ({
            sx: {
              borderTop: row.getIsExpanded() ? `2px solid ${theme.palette.tsw.tertiary}` : 'none',
              borderBottom: row.getIsExpanded()
                ? `2px solid ${theme.palette.tsw.tertiary}`
                : 'none',
              backgroundColor: row.getIsExpanded() ? theme.palette.tsw.tertiary : 'white',
              color: row.getIsExpanded() ? 'white' : 'black',
              '&:last-child': {
                borderRight: row.getIsExpanded()
                  ? `2px solid ${theme.palette.tsw.tertiary}`
                  : 'none',
              },
              '&:first-child': {
                borderLeft: row.getIsExpanded()
                  ? `2px solid ${theme.palette.tsw.tertiary}`
                  : 'none',
              },
            },
          })}
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box>
                <Button
                  variant='contained'
                  onClick={handleNewReturnModal}
                  sx={{
                    backgroundColor: theme.palette.tsw.tertiary,
                    fontSize: '1rem',
                    fontWeight: 600,
                    whiteSpace: 'pre',
                    mr: 2,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        letterSpacing: '0.05em',
                        fontWeight: 'bold',
                        textTransform: 'none',
                      }}
                    >
                      Start New Return
                    </Typography>
                  </Box>
                </Button>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtFullScreenToggleButton table={table} />
              </Box>
            );
          }}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'
                width='65%'
                flexDirection='column'
              >
                <Box display='flex' flexDirection='row' width='100%' justifyContent='flex-start'>
                  <Typography
                    variant='h5'
                    sx={{
                      textAlign: 'center',
                      letterSpacing: '0.05em',
                      color: theme.palette.tsw.secondary,
                      lineHeight: 1.2,
                    }}
                  >
                    Returns
                  </Typography>
                </Box>
              </Box>
            );
          }}
          renderDetailPanel={({ row }) => {
            return (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  p: 2,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={1} container justifyContent='flex-start'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Item #
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} container justifyContent='flex-start'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Description
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Serial #
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        UOM
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Return Qty
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={1} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Price
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3} container justifyContent='flex-end'>
                    <Box display='inline-block'>
                      <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
                        Reason
                      </Typography>
                    </Box>
                  </Grid>
                  <Divider width='100%' />
                  {Object.entries(innerData).map(([key, value]) => {
                    return (
                      <Grid container spacing={2} key={key}>
                        <Grid item xs={1} container justifyContent='flex-start'>
                          <Box display='inline-block' sx={{ width: '100%' }}>
                            <Typography variant='body1'>{key}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} container justifyContent='flex-start'>
                          <Box display='inline-block' sx={{ width: '100%' }}>
                            <Typography variant='body1'>{value.DESCRIPTION}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={2} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>
                              {value.SERIAL_NUMBERS.length > 1
                                ? 'Multiple'
                                : value.SERIAL_NUMBERS[0]}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>{value.UOM}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>{value.QUANTITY}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={1} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>${value.PRICE}</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} container justifyContent='flex-start'>
                          <Box
                            display='inline-block'
                            sx={{ width: '100%', justifyContent: 'flex-end', display: 'flex' }}
                          >
                            <Typography variant='body1'>{value.REASON}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            );
          }}
          muiExpandButtonProps={({ row, table }) => ({
            onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //set only this row to be expanded
            sx: {
              color: row.getIsExpanded() ? 'white' : 'gray',
            },
          })}
        />
      </Box>
      <NewReturnModal open={newReturnModalOpen} onClose={handleNewReturnModal} />
    </>
  );
};

export default ReturnTable;
