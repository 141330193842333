import React, { useRef, useState } from 'react';
import axios from 'axios';

// MUI
import { Box, Button, Drawer, Modal, Snackbar, TextField, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

// Primary Component
const EditAttributesButton = ({ data, rowSelection, userID }) => {
	// Local State
	const [drawerOpenBatchEdit, setDrawerOpenBatchEdit] = useState(false);
	const [batchEditFields, setBatchEditFields] = useState([]);
	const [modalToManyCategoriesToEditAttr, setModalToManyCategoriesToEditAttr] = useState(false);
	const [openSnack, setOpenSnack] = useState(false);

	// Local Refrences
	const essentialFacetValues = useRef({});

	// Functions
	const updateEditFacetValues = (e) => {
		const obj = {};
		Object.keys(essentialFacetValues.current).map(
			(fact, idx) => (obj[fact] = fact === e.target.name ? e.target.value : essentialFacetValues.current[fact])
		);
		essentialFacetValues.current = obj;
	};

	const getEssentialFieldsForCategory = (catId) => {
		const obj = {};
		const func = async () => {
			await axios
				.get(`/pa/getEssentialFacetsByCatId/${catId}`)
				.then(function (response) {
					console.log('response: ', response.data);
					setBatchEditFields(response.data);
					response.data.map((data, idx) => (obj[data.product_facet_id] = null));
					essentialFacetValues.current = { ...obj };
				})
				.catch((error) => {
					console.log('error: ' + error);
				});
		};
		func();
		setDrawerOpenBatchEdit(true);
	};

	const handleSubmissionOfFacetValues = () => {
		// product_audit_data_type_id,user_id,product_id,primary_identifier,data_value
		const func = (a, b, c, d, e) => {
			axios
				.post('/pa/insertProductAuditDataRecord', {
					product_audit_data_type_id: a,
					user_id: b,
					product_id: c,
					primary_identifier: d,
					data_value: e,
				})
				.then(function (response) {
					console.log('insert response: ', response);
					handleDrawerOpenBatchEdit();
					setOpenSnack(true);
				})
				.catch(function (error) {});
		};

		Object.keys(rowSelection).map((product_id, idx) =>
			Object.keys(essentialFacetValues.current).map((fact, idxx) =>
				essentialFacetValues.current[fact] !== null
					? func(1, userID, parseInt(product_id), parseInt(fact), essentialFacetValues.current[fact])
					: ''
			)
		);
	};

	const handleCloseSnack = () => {
		setOpenSnack(false);
	};

	const showAttributeEditor = () => {
		const catIds = [];

		const func = () => {
			Object.keys(rowSelection).map((id, idx) =>
				data
					.filter((item, idx) => item.product_code === id)
					.map(
						(row) =>
							catIds.filter((cat) => cat === row.product_category_id).indexOf(row.product_category_id) ===
								-1 && catIds.push(row.product_category_id)
					)
			);
			//setUniqueSelectedCatIds(catIds);
			catIds.length > 1 ? setModalToManyCategoriesToEditAttr(true) : getEssentialFieldsForCategory(catIds[0]);
		};
		func();
	};

	const handleClosingModalToManyCategoriesToEditAttr = () => {
		setModalToManyCategoriesToEditAttr(false);
	};

	const handleDrawerOpenBatchEdit = () => {
		setDrawerOpenBatchEdit(false);
	};

	// STYLING OBJECTS
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: 'background.paper',
		border: '2px solid #000',
		boxShadow: 24,
		p: 4,
	};

	return (
		<>
			<Button variant='contained' color='success' onClick={() => showAttributeEditor()}>
				Batch Attribute Values
			</Button>

			<Modal
				open={modalToManyCategoriesToEditAttr}
				onClose={() => handleClosingModalToManyCategoriesToEditAttr()}
				aria-labelledby='Batch Editing Warning'
				aria-describedby='Batch Editing Warning'
				sx={{ backdropFilter: 'blur(2px)' }}
			>
				<Box sx={style}>
					<Typography id='modal-modal-title' variant='h6' component='h2'>
						Product Selection Has Multiple Categories!
					</Typography>
					<Typography id='modal-modal-description' sx={{ mt: 2 }}>
						In order to edit multiple products at one time, they are required to all be in the same
						cartegory.
					</Typography>
				</Box>
			</Modal>

			<Drawer anchor='right' open={drawerOpenBatchEdit} onClose={() => handleDrawerOpenBatchEdit()}>
				<Box className='eab_wrapper' sx={{ width: '90vw', maxWidth: '1000px' }}>
					<Box className='eab_inner'>
						<Box>Attribute Maintenance:</Box>
						<Box
							className='eab_facet_wrapper'
							sx={{
								mx: 'auto',
								p: 2,
								display: 'flex',
								gap: 1,
								flexWrap: 'wrap',
								justifyContent: 'space-evenly',
							}}
						>
							{batchEditFields.map((item, idx) => (
								<TextField
									sx={{ mb: 2, width: 'auto', minWidth: '200px', maxWidth: '200px' }}
									key={idx}
									name={item.product_facet_id}
									label={item.facet}
									variant='outlined'
									onChange={(e) => updateEditFacetValues(e)}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							))}
						</Box>
						<Box sx={{ textAlign: 'right', p: 1 }}>
							<Button variant='contained' color='success' onClick={() => handleSubmissionOfFacetValues()}>
								Update Values
							</Button>
						</Box>
					</Box>
				</Box>
			</Drawer>
			<Snackbar
				sx={{ top: '100px !important', textAlign: 'center !important' }}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={openSnack}
				onClose={() => handleCloseSnack()}
				autoHideDuration={2500}
			>
				<Alert severity='success'>Data Saved. Thank You!</Alert>
			</Snackbar>
		</>
	);
};

export default EditAttributesButton;
