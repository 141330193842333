import React, { useContext, useEffect, useState } from 'react';
//import { Context } from '../../AdvertisingContext';
//import { ManufacturerContextState } from '../../../../ManufacturerContext';
import { ManufacturerContextState } from './../../../../../../../Context/ManufacturerContext';

import { ProductionProducts } from './../../../assets/ProductionProducts';

import { Box, styled } from '@mui/material';

const TotalWrapper = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(3),
	display: 'flex',
	justifyContent: 'space-between',
	fontWeight: 'bold',
	fontSize: '1.25em',
}));

const CartTotal = () => {
	const { adCartData } = useContext(ManufacturerContextState);
	const [totalAmount, setTotalAmount] = useState(null);

	useEffect(() => {
		let amounts = [];

		adCartData.items.map((item, i) =>
			amounts.push(
				ProductionProducts.filter((e) => e.production_products_id === item.production_products_id).map(
					(p, idx) => p.cost
				)
			)
		);

		const sum = amounts.reduce((acc, currentValue) => acc + parseFloat(currentValue), 0);
		setTotalAmount(sum);
	}, [adCartData, setTotalAmount]);

	return (
		<TotalWrapper>
			<Box sx={{ flex: 1 }}>Total:</Box>
			<Box>${totalAmount === undefined ? '0.00' : parseFloat(totalAmount).toFixed(2)}</Box>
		</TotalWrapper>
	);
};

export default CartTotal;
