import React from 'react';
import { Box, Divider, IconButton, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { FileOpen, ShoppingCart } from '@mui/icons-material';

const Rebate = ({ bannerId }) => {
	const theme = useTheme();
	return (
		<Box
			boxShadow={2}
			sx={{
				backgroundImage: `url(/images/brandLandingPage/rebates/${bannerId}.png)`,
				backgroundSize: 'cover',
				position: 'relative',
				aspectRatio: '21/9',
				border: 'solid 0px #666',
				borderRadius: '12px',
				overflow: 'hidden',
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					bottom: 0,
					left: 0,
					height: '35px',
					background: `linear-gradient(to top, white 0%, transparent 100%)`,
					width: '100%',
					display: 'flex',
				}}
			>
				<Grid container alignItems={'center'} justifyContent={'flex-end'} sx={{ width: '100%', pr: 1 }}>
					<Grid>
						<IconButton>
							<ShoppingCart color={'primary'} sx={{ fontSize: '20px' }} />
						</IconButton>
					</Grid>
					<Grid sx={{ width: 3, height: '20px' }}>
						<Divider orientation='vertical' />
					</Grid>
					<Grid>
						<IconButton>
							<FileOpen color={'success'} sx={{ fontSize: '20px' }} />
						</IconButton>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default Rebate;
