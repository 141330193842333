import React from 'react';
import Buttons from './components/Buttons';

const Nav = () => {
	return (
		<div>
			<Buttons />
		</div>
	);
};

export default Nav;
