import React, { useContext } from 'react';
//import { Context } from '../../AdvertisingContext';
//import { ManufacturerContextState } from '../../../../ManufacturerContext';
import { ManufacturerContextState } from './../../../../../../../Context/ManufacturerContext';

import { Box } from '@mui/material';
import CartItem from './CartItem';

const CartItems = () => {
	const { adCartData } = useContext(ManufacturerContextState);
	return (
		<Box sx={{ flex: 1 }}>
			{adCartData.items.map((item, idx) => (
				<CartItem key={idx} item={item} />
			))}
		</Box>
	);
};

export default CartItems;
