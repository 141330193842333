import { Box, Typography } from '@mui/material';
import React from 'react';

const AccountSettings = () => {
  return (
    <Box>
      <Typography variant='h4'>Account Settings</Typography>
      <Typography variant='h6'>Coming Soon</Typography>
    </Box>
  );
};

export default AccountSettings;
