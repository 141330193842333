import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import flap1 from '../../Assets/flap_1.png';
import flap2 from '../../Assets/flap_2.png';
import flap3 from '../../Assets/flap_3.png';
import flap4 from '../../Assets/flap_4.png';
import flap5 from '../../Assets/flap_5.png';

const LoadingSpinner = ({
  isLoading,
  size = 100, // Customize size of the spinner
  overlay = true, // Optional overlay background
  center = true, // Whether spinner should be centered
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(1);
  const [loadingDots, setLoadingDots] = useState('');

  const images = [flap1, flap2, flap3, flap4, flap5];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const newIndex = prevIndex + direction;
        if (newIndex === images.length - 1 || newIndex === 0) {
          setDirection(-direction);
        }
        return newIndex;
      });
    }, 150);

    return () => clearInterval(interval);
  }, [direction, images.length]);

  useEffect(() => {
    const loadingStages = ['', '.', '..', '...'];
    let textIndex = 0;

    const textInterval = setInterval(() => {
      setLoadingDots(loadingStages[textIndex]);
      textIndex = (textIndex + 1) % loadingStages.length;
    }, 400);

    return () => clearInterval(textInterval);
  }, []);

  if (!isLoading) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: center ? '50%' : 'unset',
        left: center ? '50%' : 'unset',
        transform: center ? 'translate(-50%, -50%)' : 'none',
        width: `${size}px`, // Customize size
        height: `${size}px`, // Customize size
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100,
      }}
    >
      <Box sx={{ textAlign: 'center' }}>
        <img
          src={images[currentIndex]}
          alt={`Duck Flap ${currentIndex + 1}`}
          style={{ width: `${size}px`, height: `${size}px` }}
        />
        <Typography variant='h6' sx={{ color: '#00325A', display: 'block', marginTop: '10px' }}>
          LOADING
          <Box component='span' sx={{ display: 'inline-block', width: '1em', textAlign: 'left' }}>
            {loadingDots}
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadingSpinner;
