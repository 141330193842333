import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import userInformationSlice from './UserInformationSlice';
import userPermissionSlice from './UserPermissionSlice';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mainDrawerSlice from './MainDrawerSlice';
import dataAuditSlice from './DataAuditSlice';
import brandLandingSlice from './BrandLandingSlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const reducer = combineReducers({
  // Add Reducers Here
  userInformation: userInformationSlice,
  userPermission: userPermissionSlice,
  mainDrawer: mainDrawerSlice,
  dataAudit: dataAuditSlice,
  brandId: brandLandingSlice,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export default configureStore({
  reducer: persistedReducer,
});

// export default configureStore({
//   reducer: reducer,
// });
