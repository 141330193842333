import React from 'react';
import { Box, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SearchBox = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#dcdcdc',
          height: '40px',
          width: '200px',
          paddingLeft: '16px',
          paddingRight: '16px',
        }}
      >
        <Typography variant='body2' sx={{ color: '#000', fontSize: '1.1rem' }}>
          All Departments
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <ArrowDropDownIcon sx={{ fontSize: '1.5rem', color: '#000' }} />
        </Box>
      </Box>
      <TextField
        placeholder='Search Our Catalog...'
        variant='outlined'
        size='small'
        sx={{
          backgroundColor: 'white',
          width: '600px',
          height: '40px',
          '& .MuiOutlinedInput-root': {
            height: '100%',
            paddingRight: '4px',
            '& fieldset': {
              border: 'none',
            },
            '&:hover fieldset': {
              border: 'none',
            },
            '&.Mui-focused fieldset': {
              border: 'none',
            },
          },
        }}
        InputProps={{
          style: {
            height: '100%',
          },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                sx={{
                  color: 'white',
                  backgroundColor: theme.palette.tsw.tertiary,
                  borderRadius: '0px',
                  marginRight: '-12px',
                  '&:hover': {
                    backgroundColor: theme.palette.tsw.tertiary,
                  },
                }}
              >
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBox;
