import React from 'react';
import { Box, styled } from '@mui/material';
import { ContactPhone, Email, PermPhoneMsg } from '@mui/icons-material';

const imgLinks = [
	'https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=2561&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1531384441138-2736e62e0919?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://plus.unsplash.com/premium_photo-1681489930334-b0d26fdb9ed8?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1531384441138-2736e62e0919?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://plus.unsplash.com/premium_photo-1681489930334-b0d26fdb9ed8?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
	'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
];

const Card = styled(Box)(({ theme }) => ({
	display: 'grid',
	gridTemplateColumns: '310px',
	gridTemplateRows: '220px 70px 130px',
	borderRadius: theme.spacing(3),
	marginBottom: theme.spacing(4),

	boxShadow: '6px 6px 12px rgba(0,0,80,.3)',
}));

const ImageWrapper = styled(Box)(({ theme }) => ({
	gridRow: '1 / span 3',
	gridColumn: '1 / 2',
	overflow: 'hidden',
	borderRadius: theme.spacing(3),

	'& img': {
		objectFit: 'cover',
		objectPosition: 'center	center',
		display: 'inline-block',
		width: '100%',
		height: '100%',
	},
}));

const GradBox = styled(Box)(({ theme }) => ({
	gridColumn: 1,
	gridRow: '2 / 4',
	background: `linear-gradient(to top, ${theme.palette.primary.main} 5%, rgba(0, 0, 0, 0) 100%)`,
	borderBottomLeftRadius: theme.spacing(3),
	borderBottomRightRadius: theme.spacing(3),
}));

const InnerShadowBox = styled(Box)(({ theme }) => ({
	gridColumn: 1,
	gridRow: '1 / 4',
	borderRadius: theme.spacing(3),
	boxShadow: `inset 0px 0px 50px ${theme.palette.primary.main}`,
}));

const CardText = styled(Box)(({ theme }) => ({
	gridColumn: 1,
	gridRow: '2',
	color: 'white',
	padding: `0 ${theme.spacing(2)}`,
	'.card-name': {
		fontWeight: 'bold',
		textAlign: 'center',
		fontSize: '1.5em',
		textShadow: '-1px -1px 4px rgba(0,0,0,.7)',
	},
	'.card-title': {
		fontWeight: 'bold',
		textAlign: 'center',
		fontSize: '1.2em',
		textShadow: '1px -1px 3px rgba(0,0,0,.7)',
	},
}));

const CardContact = styled(Box)(({ theme }) => ({
	gridColumn: 1,
	gridRow: '3 / 4',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	color: 'white',
	'& div': {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		gap: theme.spacing(1),
		marginBottom: theme.spacing(1),
		paddingLeft: theme.spacing(3),
	},
	'& div a': {
		color: 'white',
		textDecoration: 'none',
	},
}));

const StaffCard = ({ imgId }) => {
	return (
		<Card>
			<ImageWrapper>
				<img src={imgLinks[imgId]} alt='ted' />
			</ImageWrapper>

			<GradBox />

			<CardText>
				<div className='card-text card-name'>Tony Ocon</div>
				<div className='card-text card-title'>Director of Marketing</div>
			</CardText>

			<InnerShadowBox>&nbsp;</InnerShadowBox>

			<CardContact>
				<div>
					<PermPhoneMsg sx={{ fontSize: '1.3em' }} /> 1.800.881.3433
				</div>
				<div>
					<Email /> <a href='mailto:tony.ocon@sportssouth.biz'>tony.ocon@sportsouth.biz</a>
				</div>
				<div>
					<ContactPhone />
					<a href='/vcards/tony_vcf.vcf'>Save Contact</a>
				</div>
			</CardContact>
		</Card>
	);
};

export default StaffCard;
