import React from 'react';
import { HornadyFeed } from '../assets/HornadyFeed';

import { Container } from '@mui/material';
import HornadyCard from './HornadyCard';

console.log('hf: ', HornadyFeed);

const HornadyCards = () => {
	return (
		<Container maxWidth='xl' sx={{ my: 4, p: 3, display: 'flex', gap: 2, flexDirection: 'column' }}>
			{HornadyFeed.filter((d) => d.ParentCategory === 'Ammunition').map((e, i) => (
				<HornadyCard key={i} item={e} />
			))}
		</Container>
	);
};

export default HornadyCards;
