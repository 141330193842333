import React, { useContext, useState, useRef } from 'react';
//import { Context } from '../AdvertisingContext';
import { ManufacturerContextState } from './../../../../../../Context/ManufacturerContext';

import { random7DigitNumber } from '../../../../../../Components/_GlobalFunctions/pdm';

import {
	Box,
	Button,
	Container,
	FormControl,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	styled,
	TextField,
	useTheme,
} from '@mui/material';

import { Inbox } from '@mui/icons-material';

import {
	ProductionTypes,
	production_types_products_links,
	ProductionProducts,
	Productions,
} from './../../assets/ProductionProducts';
import SectionTitle from '../../../../../_GlobalComponents/SectionTitle';
import MainFormWrapper from './components/MainFormWrapper';

const FormGrid = styled(Box)(({ theme }) => ({
	margin: `${theme.spacing(5)} 0`,
	display: 'grid',
	gap: theme.spacing(2),
	gridTemplateColumns: 'repeat(12, 1fr)',
	gridTemplateRows: 'auto',
	gridTemplateAreas: `
 
 "mfr mfr mfr mfr type type type type ad ad ad ad" 
 ". . . . prod prod prod prod . . . ."
	"t t t t t t t t t t t t" 
 "d d d d d d d d d d d d" 
 ". . . . . . . . . sub sub sub"`,
}));

// Primary Component
const PurchaseAdvertising = () => {
	const theme = useTheme();
	const FormTitle = useRef('');
	const { adCartData, setAdCartData } = useContext(ManufacturerContextState);
	const FormDescription = useRef('');

	const [formData, setFormData] = useState({
		manufacturer_id: 0,
		ad_type_id: 0,
		production_id: 0,
		layout_id: 0,
		selected_product_id: null,
		set_products_trigger_id: null,
	});

	const handleManufacturerChange = (id) => {
		setFormData({ ...formData, manufacturer_id: id });
	};

	const handleAdTypeChange = (id) => {
		setFormData({
			...formData,
			ad_type_id: id,
			production_id: 0,
			selected_product_id: null,
			set_products_trigger_id: null,
		});
	};

	const handleProductionChange = (id) => {
		setFormData({
			...formData,
			production_id: id,
			selected_product_id: null,
			set_products_trigger_id: formData['ad_type_id'],
		});
	};

	const handleListItemClick = (id) => {
		console.log('list id: ' + id);
		setFormData({ ...formData, selected_product_id: id });
	};

	const handleAddToCart = () => {
		console.log('adCartData.items: ', adCartData.items);
		const isItemExist = adCartData.items.some(
			(e) =>
				e.ad_type_id === formData.ad_type_id &&
				e.production_id === formData.production_id &&
				e.mfr_id === formData.manufacturer_id &&
				e.production_products_id === formData.selected_product_id
		);

		if (isItemExist) {
			alert('Item already exists in the cart.');
		} else {
			setAdCartData((prevState) => ({
				...prevState,
				items: [
					...prevState.items,
					{
						line_id: random7DigitNumber(),
						ad_type_id: formData.ad_type_id,
						production_id: formData.production_id,
						mfr_id: formData.manufacturer_id,
						production_products_id: formData.selected_product_id,
					},
				],
			}));
			alert('Item added to cartt');
		}
	};

	return (
		<Box>
			<MainFormWrapper>
				<Container maxWidth='xl'>
					<SectionTitle title='Purchase Advertising' />
					<FormGrid>
						<FormControl sx={{ gridArea: 'mfr' }}>
							<TextField
								select
								label={`Manufacturer`}
								value={formData['manufacturer_id']}
								onChange={(e) => handleManufacturerChange(e.target.value)}
							>
								<MenuItem value={0}>Choose Manufactuer </MenuItem>
								<MenuItem value={451}>1791 Leather</MenuItem>
								<MenuItem value={2205}>AccuSharp</MenuItem>
								<MenuItem value={525}>Agulia Ammunition</MenuItem>
							</TextField>
						</FormControl>

						<FormControl sx={{ gridArea: 'type' }}>
							<TextField
								select
								label='Ad Type'
								value={formData['ad_type_id']}
								onChange={(e) => handleAdTypeChange(e.target.value)}
							>
								<MenuItem value={0}>Choose Ad Type </MenuItem>
								{ProductionTypes.map((pt, i) => (
									<MenuItem key={i} value={pt.product_type_id}>
										{pt.description}
									</MenuItem>
								))}
							</TextField>
						</FormControl>

						<FormControl sx={{ gridArea: 'ad' }}>
							<TextField
								select
								label='Productions'
								value={formData['production_id']}
								onChange={(e) => handleProductionChange(e.target.value)}
							>
								<MenuItem value={0}>Choose Production </MenuItem>
								{Productions.filter((pp) => pp.product_type_id === formData['ad_type_id']).map(
									(p, i) => (
										<MenuItem key={i} value={p.production_id}>
											{p.description}
										</MenuItem>
									)
								)}
							</TextField>
						</FormControl>

						{formData['set_products_trigger_id'] && (
							<FormControl sx={{ gridArea: 'prod' }}>
								<Box sx={{ fontSize: '2em', fontWeight: 'bold', color: theme.palette.primary.main }}>
									Choose Layout:
								</Box>
								<List component='nav' aria-label='main mailbox folders'>
									{production_types_products_links
										.filter((ptpl) => ptpl.product_type_id === formData['set_products_trigger_id'])
										.map((e, i) => (
											<React.Fragment key={i}>
												{e.products.map((p, idx) => (
													<ListItemButton
														key={idx}
														selected={parseInt(formData['selected_product_id']) === p}
														onClick={() => handleListItemClick(p)}
													>
														<ListItemIcon>
															<Inbox />
														</ListItemIcon>
														<ListItemText
															primary={ProductionProducts.filter(
																(pp) => pp.production_products_id === p
															).map((ppd, idx) => (
																<React.Fragment key={idx}>
																	{ppd.description}
																</React.Fragment>
															))}
															secondary={ProductionProducts.filter(
																(pp) => pp.production_products_id === p
															).map((ppd, idx) => (
																<React.Fragment
																	key={idx}
																>{`$${ppd.cost}`}</React.Fragment>
															))}
														/>
													</ListItemButton>
												))}
											</React.Fragment>
										))}
								</List>
							</FormControl>
						)}

						{formData['selected_product_id'] && (
							<Button
								variant='contained'
								color='success'
								sx={{ gridArea: 'sub' }}
								onClick={() => handleAddToCart()}
							>
								Add To Cart
							</Button>
						)}
					</FormGrid>
					<div style={{ display: 'none' }}>
						PurchaseAdvertising {ProductionTypes[0]['product_type_id']}
						{production_types_products_links
							.filter((l) => l.product_type_id === ProductionTypes[0]['product_type_id'])
							.map((item, idx) => (
								<React.Fragment key={idx}>
									{item.products.map((product, i) => (
										<React.Fragment key={i}>
											{ProductionProducts.filter(
												(pp) => pp.production_products_id === product
											).map((ppi, ii) => (
												<h1 key={ii}>
													{ppi.production_products_id} | {ppi.description} | {ppi.cost}
												</h1>
											))}
										</React.Fragment>
									))}
								</React.Fragment>
							))}
					</div>
				</Container>
			</MainFormWrapper>
		</Box>
	);
};

export default PurchaseAdvertising;
