import React from 'react';

import { Box, styled } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
	paddingTop: '10px',

	minHeight: '50vh',
	backgroundPosition: 'center center',
}));

const MainFormWrapper = ({ children }) => {
	return <Wrapper>{children}</Wrapper>;
};

export default MainFormWrapper;
