import React from 'react';

import ManufacturerNav from './components/ManufacturerNav/ManufacturerNav';
import ManufacturerContext from '../../Context/ManufacturerContext';

const Manufactuers = ({ children }) => {
	return (
		<ManufacturerContext>
			<ManufacturerNav />
			{children}
		</ManufacturerContext>
	);
};

export default Manufactuers;
