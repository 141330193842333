import { createSlice } from '@reduxjs/toolkit';

export const mainDrawerSlice = createSlice({
  name: 'userInformation',
  initialState: {
    value: false,
  },
  reducers: {
    setMainDrawer: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setMainDrawer } = mainDrawerSlice.actions;
export default mainDrawerSlice.reducer;
