import React from 'react';
import { Box } from '@mui/material';

import { PlayCircleOutline } from '@mui/icons-material';

const ClusterItem = ({ vId, vTitle }) => {
	return (
		<Box
			sx={{
				backgroundImage: `url(https://img.youtube.com/vi/${vId}/0.jpg)`,
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundPosition: 'center center',
				height: '100%',
				width: '100%',
				position: 'relative',
			}}
		>
			<Box
				sx={{
					position: 'absolute',
					bottom: 0,
					left: 0,
					height: '45px',
					background: `linear-gradient(to top, black 30%, transparent 100%)`,
					width: '100%',
					display: 'flex',
				}}
			>
				<p
					style={{
						margin: 'auto',
						color: 'white',
						padding: '4px 8px',
						fontSize: '14px',
						lineHeight: '16px',
						textAlign: 'center',
						fontWeight: 'bold',
						width: '100%',
					}}
				>
					{vTitle}
				</p>
			</Box>
			<PlayCircleOutline
				sx={{
					color: 'white',
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					fontSize: '64px',
					filter: 'drop-shadow(0px 0px 24px rgba(0,0,0,1))',
				}}
			/>
		</Box>
	);
};

export default ClusterItem;
