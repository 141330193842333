import { createSlice } from '@reduxjs/toolkit';

export const brandLandingSlice = createSlice({
	name: 'brandLanding',
	initialState: {
		brandId: 1282,
	},
	reducers: {
		setBrandId: (state, action) => {
			state.brandId = action.payload;
		},
	},
});

export const { setBrandId } = brandLandingSlice.actions;
export default brandLandingSlice.reducer;
