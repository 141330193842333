import React from 'react';
import { Box, Container } from '@mui/material';
import Cluster from './components/Cluster';

import SideItems from './components/SideItems';

const MediaCloud = () => {
	return (
		<Container maxWidth='xl' sx={{ my: 3 }}>
			<Box
				display={'grid'}
				gap={2}
				gridTemplateColumns={{ lg: '1fr 350px' }}
				gridTemplateRows={{ xs: 'auto auto', lg: 'repeat(4, 151px)' }}
				sx={{}}
			>
				<Box sx={{ gridColumn: '1/2', gridRow: { lg: '1/5' } }}>
					<Cluster />
				</Box>
				<Box
					sx={{
						gridColumn: { xs: '1/2', lg: '2/3' },
						gridRow: { lg: '1/5' },
						overflowY: 'hidden',
					}}
				>
					<SideItems />
				</Box>
			</Box>
		</Container>
	);
};

export default MediaCloud;
