import React from 'react';

import Button from './Button';
import { Box, Container, useTheme } from '@mui/material';

const Data = [
	{
		id: 0,
		label: 'Product Audit',
		icon: 'mode',
		description:
			'Your hub for enhancing and updating your product data effortlessly. Elevate your brand with precision and efficiency through our Product Audit Portal. ',
		url: '/manufacturer/audit',
	},
	{
		id: 1,
		label: 'Advertising Contracts',
		icon: 'note_add',
		description:
			'Your go-to destination for crafting bespoke advertising agreements with ease. Take control of your advertising partnerships and elevate your business with our intuitive Advertising Contracts Creation Portal.',
		url: '/manufacturer/advertising/purchase-advertising',
	},
	{
		id: 2,
		label: 'Contact Marketing',
		icon: 'contact_mail',
		description:
			'Whether you seek assistance, have questions, or simply wish to share your thoughts, our Contact Us page is your gateway to open communication. ',
		url: '/audit',
	},
	{
		id: 3,
		label: 'Percentage Co-op',
		icon: 'diversity_2',
		description:
			'Partner with us through our Percentage of Purchase Co-op program. Your contribution fuels our growth, and in return, you share in the benefits. Join us in shaping the future.',
		url: '/manufacturer/advertising/coop/co-op',
	},
	{
		id: 5,
		label: 'Fixed Amount Co-op',
		icon: 'diversity_2',
		description:
			'Join our Co-op Partnership Program, by participating in our fixed amount co-op program. Your contribution fuels our growth, and in return, you share in the benefits. Join us in shaping the future.',
		url: '/manufacturer/advertising/coop/fixed-dollar',
	},
];

const Buttons = () => {
	const theme = useTheme();
	return (
		<Box sx={{ backgroundColor: theme.palette.primary.lightScreen, p: 6, mb: 3 }}>
			<Container maxWidth='xl'>
				<Box
					sx={{
						display: 'grid',
						justifyContent: 'space-between',
						gridTemplateColumns: {
							xs: '1fr',
							md: '1fr 1fr',
						},
						gap: '20px',
					}}
				>
					{Data.map((btn, idx) => (
						<Button key={idx} btn={btn} />
					))}
				</Box>
			</Container>
		</Box>
	);
};

export default Buttons;
