import React, { PureComponent, useEffect, useState } from 'react';

import axios from 'axios';

import {
	BarChart,
	Bar,
	Rectangle,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';

const colors = ['#2196f3', '#ff5722', '#29b6f6', '#2e7d32'];
const years = [2022, 2023, 2024];

const months = [
	{ month_id: 1, month_abbr: 'Jan', month_name: 'January' },
	{ month_id: 2, month_abbr: 'Feb', month_name: 'February' },
	{ month_id: 3, month_abbr: 'Mar', month_name: 'March' },
	{ month_id: 4, month_abbr: 'Apr', month_name: 'April' },
	{ month_id: 5, month_abbr: 'May', month_name: 'May' },
	{ month_id: 6, month_abbr: 'Jun', month_name: 'June' },
	{ month_id: 7, month_abbr: 'Jul', month_name: 'July' },
	{ month_id: 8, month_abbr: 'Aug', month_name: 'August' },
	{ month_id: 9, month_abbr: 'Sep', month_name: 'September' },
	{ month_id: 10, month_abbr: 'Oct', month_name: 'October' },
	{ month_id: 11, month_abbr: 'Nov', month_name: 'November' },
	{ month_id: 12, month_abbr: 'Dec', month_name: 'December' },
];

const CustomTooltip = ({ active, payload, label }) => {
	if (active && payload && payload.length) {
		return (
			<div
				className='custom-tooltip'
				style={{
					backgroundColor: 'rgba(255,255,255,.7)',
					border: 'solid 2px white',
					backdropFilter: 'blur(3px)',
					padding: '12px',
					borderRadius: '12px',
					cursor: 'info',
					boxShadow: '2px 2px 12px rgba(100,100,200,.6)',
				}}
			>
				<div>{`Month: ${payload[0]['payload']['fullName']}`}</div>
				<div>{`2022: ${
					isNaN(payload[0]['payload']['2022'])
						? '$0.00'
						: `$${payload[0]['payload']['2022']}`
				}`}</div>
				<div>{`2023: ${
					isNaN(payload[0]['payload']['2023'])
						? '$0.00'
						: `$${payload[0]['payload']['2023']}`
				}`}</div>
				<div>{`2024: ${
					isNaN(payload[0]['payload']['2024'])
						? '$0.00'
						: `$${payload[0]['payload']['2024']}`
				}`}</div>
			</div>
		);
	}
};

// PRIMARY COMPONENT
const SimpleBarChart = ({ items }) => {
	console.log('what are items? ', items);

	const [chartData, setChartData] = useState([]);

	useEffect(() => {
		const itemList = [];

		Object.keys(items).map((item) => itemList.push(item));

		const fetchData = async () => {
			try {
				let tempArray = [];
				months.map((m, mi) =>
					tempArray.push({
						name: m.month_abbr,
						id: m.month_id,
						fullName: m.month_name,
					})
				);

				const response = await axios.get(
					`/pa/getMonthlyDollarsByProductCode/${itemList}`
				);
				const data = response.data;

				years.map((y) =>
					tempArray.map(
						(obj, i) =>
							(tempArray[i] = {
								...obj,
								[y]: parseFloat(
									data
										.filter(
											(d) =>
												d.s_year === y &&
												d.s_month === obj.id
										)
										.map((e) => e.dollars)[0]
								),
							})
					)
				);

				console.log('temp: ', tempArray);
				setChartData(tempArray);
			} catch (error) {
				//setError(error);
			}
		};

		fetchData();
	}, [items]);

	console.log('chartData: ', chartData);
	return (
		<BarChart
			width={1440}
			height={350}
			data={chartData}
			margin={{
				top: 5,
				right: 30,
				left: 20,
				bottom: 5,
			}}
		>
			<CartesianGrid strokeDasharray='3 3' />
			<XAxis dataKey='name' />
			<YAxis />
			<Tooltip content={<CustomTooltip />} />
			<Legend />
			{years.map((y, i) => (
				<Bar
					key={i}
					dataKey={y}
					fill={colors[i]}
					activeBar={<Rectangle fill='pink' stroke='blue' />}
				/>
			))}
		</BarChart>
	);
};
export default SimpleBarChart;
