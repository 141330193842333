import React, { useState } from 'react';
import { Card, CardContent, CardHeader, CardMedia, Typography, Box } from '@mui/material';
import PlanogramModal from './PlanogramModal';

const PlanogramCard = ({ planogram }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box display='flex' justifyContent='center' sx={{ borderRadius: 2 }}>
        <Card
          onClick={handleOpen}
          sx={{
            maxWidth: 345,
            boxShadow: 3,
            borderRadius: 2,
            backgroundColor: '#ffffff',
            outline: 3,
            outlineColor: 'primary.main',
            cursor: 'pointer',
            transition: 'transform 0.2s, box-shadow 0.2s',
            '&:hover': {
              transform: 'scale(1.03)',
              boxShadow: 6,
            },
          }}
        >
          <CardHeader
            title={planogram.page_title}
            subheader={planogram.message}
            sx={{
              textAlign: 'center',
              color: 'primary.main',
              '& .MuiCardHeader-subheader': {
                color: planogram.messageColor,
                textAlign: 'justify',
                pt: 1,
              },
            }}
          />
          <Box sx={{ px: 2, pb: 2 }}>
            <CardMedia
              component='img'
              image={planogram.header_image}
              alt={planogram.page_title}
              sx={{ objectFit: 'cover', borderRadius: 2 }}
            />
          </Box>
        </Card>
      </Box>
      <PlanogramModal planogram={planogram} open={open} onClose={handleClose} />
    </>
  );
};

export default PlanogramCard;
