import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Container, Typography, useTheme } from '@mui/material';
import CoopFundForm from './CoopFundForm';

const typeText = [
	{
		id: 'co-op',
		text: `Sports South welcomes your consideration in participating in our marketing efforts. As a
participent, we're asking for a percentage of the purchased price of the products we acquire
from you during a specified timeframe. In return, we pledge to allocate these funds towards
advertising initiatives aimed at promoting your products and extending the recognition of
your brand to the marketplace. This collaborative effort not only strengthens our
partnership but also amplifies brand visibility and market presence for both of us. Through
this strategic collaboration, we aim to drive sales and maximize the impact of our
advertising campaigns, ultimately enriching the entire supply chain.`,
	},
	{
		id: 'fixed-dollar',
		text: `Sports South welcomes your consideration in participating in our marketing efforts. As a
participent, we're asking for you to determine specified amount of dollars to be allocated for advertising initiatives within a specified timeframe. In return, we pledge to allocate these funds towards
advertising initiatives aimed at promoting your products and extending the recognition of
your brand to the marketplace. This collaborative effort not only strengthens our
partnership but also amplifies brand visibility and market presence for both of us. Through
this strategic collaboration, we aim to drive sales and maximize the impact of our
advertising campaigns, ultimately enriching the entire supply chain.`,
	},
];
const CoopFund = () => {
	const theme = useTheme();
	const userInformation = useSelector((state) => state.userInformation.value);

	const { fundId } = useParams();
	return (
		<>
			<Container maxWidth='xl' sx={{ py: 4 }}>
				<Box sx={{ gridArea: 'title', mb: 3 }}>
					<Typography variant={'h4'} sx={{ py: 2, color: theme.palette.primary.main, fontWeight: 'bold' }}>
						Participate In Sports South Marketing!
					</Typography>
					{/* <Box sx={{ fontWeight: 'bold' }}>
						Agent: {userInformation.firstName} {userInformation.lastName} ({userInformation.contactID})
					</Box> */}
				</Box>
				<Box sx={{ display: 'flex', gap: 8 }}>
					<Box sx={{ width: '600px' }}>
						<Box sx={{ fontSize: '20px', lineHeight: '32px' }}>
							{typeText
								.filter((t) => t.id === fundId)
								.map((e, i) => (
									<React.Fragment key={i}>{e.text}</React.Fragment>
								))}
						</Box>
					</Box>
					<Box sx={{ flex: 1 }}>
						<CoopFundForm fundId={fundId} />
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default CoopFund;
