import React from 'react';
import { Checkbox, Box } from '@mui/material';

const TableRowActions = ({ row, onCheckChange }) => {
  return (
    <Box>
      <Checkbox
        checked={row.original.isChecked || false}
        onChange={(e) => onCheckChange(row, e.target.checked)}
      />
    </Box>
  );
};

export default TableRowActions;
