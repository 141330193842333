import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Advertising from '../Components/Manufacturers/components/Advertising/Advertising';
import PurchaseAdvertising from '../Components/Manufacturers/components/Advertising/components/PurchaseAdvertising/PurchaseAdvertising';
import CoopFund from '../Components/Manufacturers/components/Advertising/components/AdvertisingCoop/CoopFund';

const AdvertisingRoute = () => {
	return (
		<Advertising>
			<Routes>
				<Route path='/purchase-advertising' element={<PurchaseAdvertising />} />
				<Route path='/coop/:fundId' element={<CoopFund />} />

				<Route path='/*' element={'broken ad link'} />
			</Routes>
		</Advertising>
	);
};

export default AdvertisingRoute;
