import React, { useEffect, useState } from 'react';
import { ProductFeed } from './assets/ProductFeed';

import { Box, Container } from '@mui/material';
import HornadyCards from './components/HornadyCards';

// async function fetchData(key) {
// 	try {
// 		const response = await fetch('/productData/getHornadyPIMData', {
// 			method: 'POST',
// 			headers: {
// 				'Content-Type': 'application/json',
// 			},
// 			body: JSON.stringify({ key: key }),
// 		});

// 		if (!response.ok) {
// 			throw new Error('Failed to fetch data');
// 		}

// 		const data = await response.json();
// 		return data;
// 	} catch (error) {
// 		console.error('Error fetching data:', error);
// 		return null;
// 	}
// }

const HornadyPIM = () => {
	//const [data, setData] = useState([{}]);

	// useEffect(() => {
	// 	const apiKey = '3349988ae46f41a9ae88f125364d34a7';
	// 	fetchData(apiKey)
	// 		.then((data) => {
	// 			setData(data);
	// 			// Do something with the data
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error:', error);
	// 		});
	// }, []);
	return (
		<div>
			HornadyPIM
			{/* {HornadyFeed.filter((d) => d.ParentCategory === 'Ammunition').map((item, idx) =>
				ProductFeed.filter((e) => e.upc === item.UPC).map((ele, idxx) => (
					<React.Fragment key={idxx}>
						<div key={ele.product_id}>
							details: {ele.description} | {item.CountryofOrigin}
							<img
								src={item.ProductFamilyImage ? item.ProductFamilyImage : item.ProductImage}
								alt={item.ProductDescription}
								style={{ width: '200px', height: 'auto' }}
							/>
						</div>
					</React.Fragment>
				))
			)} */}
			<HornadyCards />
		</div>
	);
};

export default HornadyPIM;
