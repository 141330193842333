import React from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { setUserInformation } from '../../../Redux/UserInformationSlice';
import { useSelector } from 'react-redux';
import { CssBaseline, Grid, TextField, Typography, Button, useTheme } from '@mui/material';
import { Alert } from '@mui/material';
import Logo from '../../../Assets/BugLogo.png';
import { Box } from '@mui/system';
import jwt_decode from 'jwt-decode';
import { setUserPermission } from '../../../Redux/UserPermissionSlice';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiFilledInput-root': {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  '& label.MuiInputLabel-root': {
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: 'white',
  },
  '& .MuiInputBase-input': {
    color: 'black',
    backgroundColor: 'white',
  },
  '& .Mui-focused': {
    color: theme.palette.text.primary,
    backgroundColor: 'white',
    '& .MuiInputLabel-root': {
      color: 'rgba(0, 0, 0, 0.54)',
      backgroundColor: 'white',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
  backgroundColor: 'white',
  color: 'black',
}));

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const userInformation = useSelector((state) => state.userInformation.value);
  const userPermission = useSelector((state) => state.userPermission.value);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLoginClick = (e) => {
    e.preventDefault();
    axios
      .post('/users/appLogin', {
        username: username,
        password: password,
      })
      .then((response) => {
        const decoded = jwt_decode(response.data.token);
        dispatch(
          setUserInformation({
            userID: decoded.user_id,
            contactID: decoded.contact_id,
            token: response.data.token,
            firstName: decoded.first_name,
            lastName: decoded.last_name,
            businessName: decoded.business_name,
          })
        );
        dispatch(setUserPermission(decoded.elements));
        console.log(userPermission);
        navigate('/');
      })
      .catch((error) => {
        console.log(error);
        setError('Incorrect username or password. Please try again.');
      });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <Grid container sx={{ backgroundColor: theme.palette.primary.main, height: '100%' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <img
          src={Logo}
          alt='Logo'
          style={{
            maxWidth: '85%',
            maxHeight: '85%',
            backgroundColor: theme.palette.background.default,
          }}
        />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box sx={{ maxWidth: '600px', width: '100%', padding: theme.spacing(4) }}>
          {error && <Alert severity='error'>{error}</Alert>}
          <Typography component='h1' variant='h5' sx={{ color: 'white', textAlign: 'center' }}>
            Employee Login
          </Typography>
          <form onSubmit={handleLoginClick}>
            <StyledTextField
              variant='filled'
              margin='normal'
              required
              fullWidth
              id='username'
              label='Username'
              name='username'
              autoComplete='username'
              autoFocus
              value={username}
              onChange={handleUsernameChange}
            />
            <StyledTextField
              variant='filled'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={handlePasswordChange}
            />
            <StyledButton type='submit' fullWidth variant='contained'>
              Sign In
            </StyledButton>
            <Box sx={{ mt: 2 }}>
              <Typography variant='subtitle2' sx={{ color: 'white' }}>
                Contact Help Desk if you have trouble logging in.
              </Typography>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
