import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled, useTheme } from '@mui/material';
import Icon from '@mui/material/Icon';

const ButtonWrapper = styled(Box)(({ theme }) => ({
	display: 'grid',
	padding: theme.spacing(2),
	borderRadius: '12px',
	gridTemplateColumns: '100px 1fr',
	gridTemplateRows: 'auto 1fr',
	backgroundColor: 'white',
	border: 'solid 2px rgb(220,220,220)',
	boxShadow: '2px 2px 5px rgba(0,0,0,.2)',
	transition: '300ms ease-in-out',
	cursor: 'pointer',
	position: 'relative',
	'&:hover': {
		borderColor: theme.palette.secondary.main,
	},
}));

// const Background = styled(Box)(({ theme, background }) => ({
// 	overflow: 'hidden',
// 	paddingBottom: '12px',
// 	background: `linear-gradient(to bottom, rgba(255,255,255,0) 0% , rgba(255,255,255,1) 100% ), url(${background})`,
// 	backgroundRepeat: 'no-repeat',
// 	backgroundSize: 'cover',
// 	backgroundPosition: 'top center',
// 	opacity: '.3',
// 	position: 'absolute',
// 	width: '100%',
// 	height: '80%',
// }));

const Button = ({ btn }) => {
	const theme = useTheme();
	const navigate = useNavigate();

	return (
		<ButtonWrapper onClick={() => navigate(btn.url)}>
			<Box sx={{ display: 'flex', gridColumn: '1/2', gridRow: '1/3' }}>
				<Icon
					sx={{
						color: 'white',
						margin: 'auto',
						fontSize: 50,
						display: 'inline-block',
						width: '80px',
						height: '80px',
						backgroundColor: 'red',
						p: 2,
						borderRadius: '50%',
					}}
				>
					{btn.icon}
				</Icon>
			</Box>
			<Box
				sx={{
					gridColumn: '2/3',
					gridRow: '1/2',
					fontWeight: 'bold',
					color: theme.palette.primary.main,
					fontSize: '1.3em',
					px: 1,
				}}
			>
				{btn.label}
			</Box>
			<Box sx={{ gridColumn: '2/3', gridRow: '2/3', px: 1, pt: 1, pr: 4, fontWeight: 'bold' }}>
				{btn.description}
			</Box>
		</ButtonWrapper>
	);
};

export default Button;
