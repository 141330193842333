import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setMainDrawer } from '../../Redux/MainDrawerSlice';
import LogoName from '../../Assets/logo.png';
import LogoDuck from '../../Assets/ssDuckSquare.png';
import DrawerMenu from './Components/DrawerMenu';
import { useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import Logout from '@mui/icons-material/Logout';
import { resetUserInformation } from '../../Redux/UserInformationSlice';
import { resetUserPermission } from '../../Redux/UserPermissionSlice';
import PageTitle from '../_GlobalFunctions/PageTitle';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import Settings from '@mui/icons-material/Settings';
import GroupAdd from '@mui/icons-material/GroupAdd';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	flexGrow: 1,
	paddingTop: `${theme.spacing(8)}`,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	marginLeft: `-${drawerWidth}px`,
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	}),
	display: 'flex',
	flexDirection: 'column',
	// minHeight: '100vh',
	// maxHeight: '100vh',
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
	background: theme.palette.primary.main,
	color: theme.palette.primary.contrastText,
}));

export default function PersistentDrawerLeft() {
	const location = useLocation();
	const currentPathname = location.pathname;

	const locationDataAudit = currentPathname.includes('/product-audit');
	const locationBrandLanding = currentPathname.includes('/brand-landing-page');

	const isScreenSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
	const theme = useTheme();
	const drawerState = useSelector((state) => state.mainDrawer.value);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userInformation = useSelector((state) => state.userInformation.value);
	const userLastName = useSelector((state) => state.userInformation.value.lastName);
	const userFirstName = useSelector((state) => state.userInformation.value.firstName);
	const userAvatar = useSelector((state) => state.userInformation.value.avatarLink);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		localStorage.removeItem('token');
		navigate('/login');
		dispatch(resetUserInformation());
		dispatch(resetUserPermission());
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar position='fixed' open={drawerState} sx={{ boxShadow: locationDataAudit ? 'none' : null }}>
				<Toolbar sx={{ justifyContent: 'space-between', maxHeight: '64px' }}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<IconButton
							color='inherit'
							aria-label='open drawer'
							onClick={() => dispatch(setMainDrawer(true))}
							edge='start'
							sx={{
								mr: 2,
								...(drawerState && { display: 'none' }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
							<img
								src={LogoDuck}
								alt='logo'
								style={{
									maxWidth: 90,
									height: 'auto',
									marginBottom: 5,
								}}
							/>
						</Box>
					</div>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{isScreenSmall && (
							<Typography
								variant='h6'
								noWrap
								component='div'
								sx={{ display: { xs: 'block', sm: 'none' } }}
							>
								{PageTitle()}
							</Typography>
						)}
						{!isScreenSmall && (
							<Typography
								variant='h4'
								noWrap
								component='div'
								sx={{ display: { xs: 'none', sm: 'block' } }}
							>
								{PageTitle()}
							</Typography>
						)}
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyItems: 'end',
						}}
					>
						{isScreenSmall && (
							<Typography
								variant='h6'
								noWrap
								component='div'
								sx={{ display: { xs: 'block', sm: 'none' } }}
							>
								{userFirstName.charAt(0)}
								{userLastName.charAt(0)}
							</Typography>
						)}
						{!isScreenSmall && (
							<Typography
								variant='h5'
								noWrap
								component='div'
								sx={{ display: { xs: 'none', sm: 'block' } }}
							>
								{`${userFirstName} ${userLastName}`}
							</Typography>
						)}
						<ExpandMoreIcon
							onClick={handleClick}
							size='small'
							sx={{ ml: { xs: 0, sm: 2 } }}
							aria-controls={open ? 'account-menu' : undefined}
							aria-haspopup='true'
							aria-expanded={open ? 'true' : undefined}
						/>
					</Box>
					<Menu
						anchorEl={anchorEl}
						id='account-menu'
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{
							horizontal: 'right',
							vertical: 'top',
						}}
						anchorOrigin={{
							horizontal: 'right',
							vertical: 'bottom',
						}}
					>
						<MenuItem onClick={(event) => event.stopPropagation()} disableRipple>
							<Typography
								variant='h6'
								noWrap
								component='div'
								sx={{
									color: 'text.primary',
									marginBottom: '0.25em',
								}}
							>
								{`${userInformation.businessName}`}
							</Typography>
						</MenuItem>
						<Divider />
						<MenuItem onClick={() => navigate('/account-settings')}>
							<ListItemIcon>
								<Settings fontSize='small' />
							</ListItemIcon>
							Account Settings
						</MenuItem>
						<MenuItem onClick={() => navigate('/manage-users')}>
							<ListItemIcon>
								<GroupAdd fontSize='small' />
							</ListItemIcon>
							Manage Users
						</MenuItem>
						<MenuItem onClick={() => navigate('/user-preferences')}>
							<ListItemIcon>
								<ManageAccounts fontSize='small' />
							</ListItemIcon>
							User Preferences
						</MenuItem>
						<Divider />
						<MenuItem onClick={handleLogout}>
							<ListItemIcon>
								<Logout fontSize='small' />
							</ListItemIcon>
							Logout
						</MenuItem>
					</Menu>
				</Toolbar>
			</AppBar>

			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,

					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
					},
				}}
				variant='persistent'
				anchor='left'
				open={drawerState}
			>
				<DrawerHeader>
					<img src={LogoName} alt='logo' style={{ maxHeight: 25 }} />
					<IconButton onClick={() => dispatch(setMainDrawer(false))}>
						{theme.direction === 'ltr' ? (
							<ChevronLeftIcon style={{ color: 'white' }} />
						) : (
							<ChevronRightIcon style={{ color: 'white' }} />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					<DrawerMenu />
				</List>
			</Drawer>
			<Main open={drawerState} sx={{ backgroundColor: locationDataAudit ? '#f3f5f6' : null, width: '100%' }}>
				<Box
					className='outlet-wrapper'
					sx={{
						flexGrow: 1,
						overflowY: 'auto',
						py: 1,
						px: 0,
					}}
				>
					<Outlet />
				</Box>
			</Main>
		</Box>
	);
}
