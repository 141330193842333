import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const LoginCheckRoute = ({ children }) => {
  const userInformation = useSelector((state) => state.userInformation.value);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInformation.userID) {
      navigate('/landing');
    }
  }, [userInformation, navigate]);

  return children;
};

export default LoginCheckRoute;
