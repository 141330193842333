import React from 'react';

import { Box, Container, styled } from '@mui/material';
import StaffCard from './components/StaffCard';

const MeetStaff = styled(Box)(({ theme }) => ({
	fontWeight: 'bold',
	color: theme.palette.secondary.main,
	fontSize: '2.5em',
}));

const StaffCards = () => {
	return (
		<>
			<Container maxWidth='xl'>
				<MeetStaff>Meet Your Marketing Team!</MeetStaff>
			</Container>
			<Container maxWidth='xl' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', my: 4 }}>
				{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((staff, idx) => (
					<StaffCard key={idx} imgId={idx} />
				))}
			</Container>
		</>
	);
};

export default StaffCards;
