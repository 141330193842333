import React, { useState, useRef } from 'react';
import { BottomNavigation, BottomNavigationAction, Menu, MenuItem, Divider } from '@mui/material';
import {
  LocalMall as OrdersIcon,
  Payment as InvoicesIcon,
  Loyalty as PromotionsIcon,
  Visibility as ViewReportsIcon,
  Star as WishlistIcon,
  Storefront as BrandsIcon,
  MoreHoriz as MoreIcon,
  Description as DocumentsIcon,
  Link as LinksIcon,
  Settings as SettingsIcon,
  Favorite as FavoriteIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

const TSWBottomNav = () => {
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const moreRef = useRef(null);

  const handleMenuOpen = () => {
    setAnchorEl(moreRef.current);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      sx={{
        zIndex: 500,
        backgroundColor: theme.palette.tsw.tertiary,
        position: 'fixed',
        borderTop: `3px solid ${theme.palette.tsw.secondary}`,
        bottom: 0,
        left: 0,
        right: 0,
        '& .MuiBottomNavigationAction-root': {
          maxWidth: 'none',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: '#fff',
          '&:hover': {
            backgroundColor: theme.palette.tsw.secondary,
            color: '#fff',
          },
          '&.Mui-selected': {
            color: '#fff',
            '&:hover': {
              backgroundColor: theme.palette.tsw.secondary,
            },
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          },
        },
      }}
    >
      <BottomNavigationAction
        label='Orders & Invoices'
        icon={<OrdersIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-order-history'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />
      <BottomNavigationAction
        label='Make Payments'
        icon={<InvoicesIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-account-balance'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />

      <BottomNavigationAction
        label='Wishlist'
        icon={<WishlistIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-wishlist'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />

      <BottomNavigationAction
        label='Favorites'
        icon={<FavoriteIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-favorites'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />

      <BottomNavigationAction
        label='Promotions & Programs'
        icon={<PromotionsIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-promotions-programs'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />

      <BottomNavigationAction
        label='View Reports'
        icon={<ViewReportsIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-reports'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />

      <BottomNavigationAction
        label='Brands We Carry'
        icon={<BrandsIcon />}
        component='a'
        href='https://www.theshootingwarehouse.com/tsw-brands'
      />
      <Divider orientation='vertical' flexItem sx={{ backgroundColor: 'white' }} />

      <BottomNavigationAction
        label='More'
        icon={<MoreIcon />}
        onClick={handleMenuOpen}
        ref={moreRef}
      />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.tsw.tertiary,
            color: '#fff',
            boxShadow: 'none',
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            minWidth: 200,
            marginBottom: '56px',
          },
          '& .MuiMenuItem-root': {
            '&:hover': {
              backgroundColor: theme.palette.tsw.secondary,
            },
          },
        }}
      >
        <MenuItem onClick={handleMenuClose}>
          <DocumentsIcon sx={{ marginRight: 1 }} />
          Documents
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <LinksIcon sx={{ marginRight: 1 }} />
          Industry Links
        </MenuItem>
        <MenuItem onClick={handleMenuClose}>
          <SettingsIcon sx={{ marginRight: 1 }} />
          Account Settings
        </MenuItem>
      </Menu>
    </BottomNavigation>
  );
};

export default TSWBottomNav;
