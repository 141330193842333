import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingIcon = ({ show }) => {
  const [doShow, setDoShow] = useState(show ? show : false);

  useEffect(() => {
    console.log('do show: ' + show);
    setDoShow(show);
  }, [show]);
  return (
    <>
      {doShow ? (
        <Box sx={{ display: 'flex', position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', backgroundColor: 'rgba(0,0,0,.4)', zIndex: 10000 }}>
          <CircularProgress sx={{ margin: 'auto' }} />
        </Box>
      ) : null}
    </>
  );
};

export default LoadingIcon;
