import MfrProductTable from './components/MfrProductTable';
import { useContext } from 'react';
import { ManufacturerContextState } from './../../../../Context/ManufacturerContext';

import { Box } from '@mui/material';

const ProductAudit = () => {
	const { name } = useContext(ManufacturerContextState);
	return (
		<Box className='DataAudit' sx={{ display: 'flex', p: 3 }}>
			<Box sx={{ border: 'solid 1px blue', minWidth: '300px', maxWidth: '300px' }}>left side box {name}</Box>
			<Box sx={{ flex: 1 }}>
				<MfrProductTable />
			</Box>
		</Box>
	);
};

export default ProductAudit;
