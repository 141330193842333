import React, { useState } from 'react';

import { Box, Button, Drawer } from '@mui/material';
import SimpleBarChart from './components/SimpleBarChart';

const ButtonViewPerformance = ({ selectedItems }) => {
	const [open, setOpen] = useState(false);

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<Box sx={{ maxWidth: '100%' }}>
			<Button
				variant='contained'
				color='warning'
				onClick={() => setOpen(true)}
			>
				View Product Performance
			</Button>
			<Drawer
				anchor='right'
				open={open}
				onClose={() => handleDrawerClose()}
			>
				<Box sx={{ width: { xs: '90%' } }}>
					<SimpleBarChart items={selectedItems} />
				</Box>
			</Drawer>
		</Box>
	);
};

export default ButtonViewPerformance;
