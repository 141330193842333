import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { MaterialReactTable } from 'material-react-table';

import axios from 'axios';

import { Box, Button, styled, Tooltip, useTheme } from '@mui/material';
import LoadingIcon from './LoadingIcon';
import EditAttributesButton from './EditAttributesButton';
import DetailPanelProductDetails from './DetailPanelProductDetails';
import ButtonViewPerformance from './ButtonViewPerformance/ButtonViewPerformance';
//import SimpleBarChart from './ButtonViewPerformance/components/SimpleBarChart';

// STYLED COMPONENTS
const Wrapper = styled(Box)(({ theme }) => ({
	padding: 16,
	borderRadius: 12,
	margin: 'auto',
}));

// PRIMARY COMPONENT
const MfrProductTable = () => {
	// INITs

	const theme = useTheme();

	// LOCAL STATE
	const [data, setData] = useState([]);
	const [doShow, setDoShow] = useState(false);
	const [rowSelection, setRowSelection] = useState({});
	//const [uniqueSelectedCatIds, setUniqueSelectedCatIds] = useState([]);

	// REDUX STATE
	const mfrIdForTableData = useSelector((state) => state.dataAudit.mfrIdForTableData) || 156;
	const userID = useSelector((state) => state.userInformation.value.userID);

	console.log('mfrIdForTableData: ' + mfrIdForTableData);

	useEffect(() => {
		setDoShow(false);
		const func = async () => {
			await axios
				.get(`/pa/getPAProductDetailsForMFRId/${mfrIdForTableData}`)
				.then(function (response) {
					setData(response.data);
					setDoShow(false);
				})
				.catch((error) => {
					console.log('error: ' + error);
				});
		};

		mfrIdForTableData > 0 ? func() : setDoShow(false);
	}, [mfrIdForTableData]);

	// COLUMNS FOR TABLE
	const columns = [
		{
			accessorKey: 'product_code',
			header: 'SS#',
			maxSize: 90,
			size: 90,
			muiTableBodyCellProps: {
				sx: { color: theme.palette.primary.main, fontWeight: 'bold' },
			},
			Cell: ({ row }) => (
				<Box sx={{}}>
					<Tooltip title={`Product ID: ${row.original.product_id}`} arrow>
						<Box>{row.original.product_code}</Box>
					</Tooltip>
				</Box>
			),
		},
		{
			accessorKey: 'OnHand',
			header: 'QTY',
			Cell: ({ row }) => (
				<Box
					sx={{
						maxWidth: '55px',
						fontWeight: 'bold',
						padding: '8px 12px',
						borderRadius: '8px',
						color:
							row.original.qtyoh < 10
								? theme.palette.error.dark
								: row.original.qtyoh >= 11 && row.original.qtyoh < 40
								? theme.palette.warning.main
								: row.original.qtyoh >= 41 && row.original.qtyoh < 100
								? '#fedc56'
								: theme.palette.success.main,
						textShadow: '2px 2px 3px rgba(0,0,0,0.1)',
					}}
				>
					{row.original.qtyoh}
				</Box>
			),
		},
		//   { accessorKey: 'sls30', header: 'Last 30', size: 50 },
		//   { accessorKey: 'qtyytd', header: 'YTD', size: 50 },orig_sort
		{
			accessorKey: 'pic_ref',
			header: 'Photo',
			// Header: <i style={{ color: 'red' }}>Age</i>,
			maxSize: 135,
			size: 135,

			Cell: ({ row }) => (
				<Box
					sx={{
						position: 'relative',
						width: '100px',
						height: '75px',
					}}
				>
					<img
						key={row.original.product_id}
						src={`https://media.server.theshootingwarehouse.com/thumbnail/${row.original.pic_ref}.jpg`}
						alt={`product | ${row.original.product_id}`}
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							height: '100%',
							width: '100%',
							display: 'block',
							objectFit: 'contain',
							objectPosition: 'center center',
							cursor: 'pointer',
						}}
						onClick={() => console.log(row.original.pic_ref)}
					/>
				</Box>
			),
		},

		{
			accessorKey: 'category',
			header: 'Category',
			size: 160,
			maxSize: 160,
		},

		{
			accessorKey: 'description',
			header: 'Description',
			size: 380,
			maxSize: 380,
		},
		{
			accessorKey: 'model',
			header: 'Model',
		},
		{
			accessorKey: 'series',
			header: 'Series',
		},
		// { accessorKey: 'orig_sort', header: 'Orig Sort', size: 150 },
	];

	const showItemsOnTSW = () => {
		var item_numbers = '';
		Object.keys(rowSelection).map((row) => (item_numbers += row + ','));
		if (item_numbers.endsWith(',')) {
			item_numbers = item_numbers.slice(0, -1);
		}
		window.open(`https://www.theshootingwarehouse.com/?search_text=(${item_numbers})`, '_blank');
	};

	useEffect(() => {
		const func = () => {
			Object.keys(rowSelection).map((id, idx) => console.log('product_id: ' + id));
		};
		func();
	}, [rowSelection]);

	useEffect(() => {
		setRowSelection({});
	}, [data]);

	return (
		<Wrapper className='MfrProductTable'>
			<Box
				sx={{
					p: 1,
					display: 'flex',
					justifyContent: 'flex-end',
					gap: 2,
				}}
			>
				{Object.keys(rowSelection).length > 0 ? (
					<Button variant='contained' color='error' onClick={() => setRowSelection({})}>
						Clear Selections
					</Button>
				) : null}

				{Object.keys(rowSelection).length > 0 ? (
					<EditAttributesButton data={data} rowSelection={rowSelection} userID={userID} />
				) : null}
				{Object.keys(rowSelection).length > 0 ? <ButtonViewPerformance selectedItems={rowSelection} /> : null}
				{Object.keys(rowSelection).length > 0 ? (
					<Button variant='contained' color='info' onClick={() => showItemsOnTSW()}>
						View Online
					</Button>
				) : null}
			</Box>
			<Box>
				{/* INSERTION OF THE SIMPLE BAR CHART */}
				{/* <SimpleBarChart items={[23920]} /> */}
			</Box>
			<MaterialReactTable
				columns={columns}
				data={data}
				muiTableHeadProps={{
					sx: {},
				}}
				muiTableContainerProps={{
					sx: { maxHeight: 'calc(100vh - 390px)' },
				}}
				muiTablePaperProps={({ table }) => ({
					//not sx
					style: {
						zIndex: table.getState().isFullScreen ? 1300 : undefined,
					},
				})}
				enablePagination
				enableStickyHeader
				enableRowSelection
				getRowId={(row) => row.product_code} //give each row a more useful id
				onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
				state={{ rowSelection }} //pass our managed row selection state to the table to use
				renderDetailPanel={({ row }) => (
					<Box className='render_detail_panel_wrapper' sx={{ width: '100%', maxWidth: '100%' }}>
						{<DetailPanelProductDetails row={row} user_id={userID} />}
					</Box>
				)}
			/>

			<LoadingIcon show={doShow} />
		</Wrapper>
	);
};

export default MfrProductTable;
