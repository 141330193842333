import React from 'react';
import Logo from '../../Assets/BugLogo.png';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserInformation } from '../../Redux/UserInformationSlice';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { setUserPermission } from '../../Redux/UserPermissionSlice';
import { useNavigate } from 'react-router-dom';

const Handshake = () => {
  const dispatch = useDispatch();
  const userInformation = useSelector((state) => state.userInformation.value);
  const userPermission = useSelector((state) => state.userPermission.value);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const fetchUser = async () => {
      try {
        const response = await axios.post(`/app/loginHandshake`, {
          hash: token,
        });
        console.log(response.data);
        const decoded = jwt_decode(response.data.token);
        dispatch(
          setUserInformation({
            userID: decoded.user_id,
            contactID: decoded.contact_id,
            token: response.data.token,
            firstName: decoded.first_name,
            lastName: decoded.last_name,
            avatarLink: decoded.avatar_link,
            employeeNumber: decoded.employee_number,
          })
        );
        dispatch(setUserPermission(decoded.elements));
        console.log(userPermission);
        navigate(`/${decoded.destination_path.split('/').pop()}`, { replace: true });
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    if (token) {
      fetchUser();
    }
  }, [navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={Logo} alt='Logo' style={{ width: 500, height: 'auto', paddingTop: 55 }} />
      <Typography variant='h4' style={{ textAlign: 'center' }}>
        Logging in...
      </Typography>
    </Box>
  );
};

export default Handshake;
