import React from 'react';
import { Box, Typography, TextField } from '@mui/material';

const getColumns = ({ handleItemOpenModal, handleQtyChange }) => [
  {
    header: 'Fill Qty',
    accessorKey: 'fill_qty',
    size: 40,
    Cell: ({ renderedCellValue, row }) => {
      const fillQty = Number(renderedCellValue) || 0; // Convert to number for comparison
      const availableQty = Number(row.original.available_quantity);
      const isOverAvailable = fillQty > availableQty;

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant='outlined'
            size='small'
            value={renderedCellValue}
            onChange={(e) => handleQtyChange(row.original.item_number, e.target.value)}
            sx={{
              maxWidth: '100%',
              '& .MuiInputBase-input': {
                color: isOverAvailable ? 'red' : 'inherit', // Conditional text color
              },
            }}
          />
        </Box>
      );
    },
  },
  {
    header: 'Available',
    accessorKey: 'available_quantity',
    size: 40,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Peg #',
    accessorKey: 'peg_number',
    size: 35,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'Item #',
    accessorKey: 'item_number',
    size: 40,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant='body1'
          onClick={() => handleItemOpenModal(renderedCellValue)}
          sx={{ color: '#1976d2', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {renderedCellValue}
        </Typography>
      </Box>
    ),
  },
  {
    header: 'Cost',
    accessorKey: 'discount_price',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>${renderedCellValue}</Typography> {/* Add $ symbol */}
      </Box>
    ),
  },
  {
    header: 'Description',
    accessorKey: 'description',
    size: 300,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
  {
    header: 'UPC #',
    accessorKey: 'upc',
    size: 100,
    Cell: ({ renderedCellValue }) => (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1'>{renderedCellValue}</Typography>
      </Box>
    ),
  },
];

export default getColumns;
