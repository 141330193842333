import React from 'react';
import { Box } from '@mui/material';
import ClusterItem from './ClusterItem';

const videoFeed = [
	{
		id: '6t1GTXVVydg',
		si: 'P3-eOYXmwqRCjDve',
		title: 'Claymore Take Down - How-to',
	},
	{
		id: '2_p0Jt4wtpY',
		si: 'P3-jEmt4SEAswy7M-UW',
		title: 'Caldwell Flash Bang | Key Features',
	},
	{
		id: 'ovwGuTcsDr8',
		si: 'cg-cux-tyTKTrSqU',
		title: 'Tips for shooting in new locations with Jim See',
	},
	{
		id: 'xjgBQhqrqFQ',
		si: 'I-jiEXrP-2yWcODa',
		title: 'Unboxing your E-MAX Shadows Pro',
	},
	{
		id: '2_p0Jt4wtpY',
		si: 'P3-jEmt4SEAswy7M-UW',
		title: 'Caldwell Flash Bang | Key Features',
	},
	{
		id: 'ovwGuTcsDr8',
		si: 'cg-cux-tyTKTrSqU',
		title: 'Tips for shooting in new locations with Jim See',
	},
	{
		id: 'xjgBQhqrqFQ',
		si: 'I-jiEXrP-2yWcODa',
		title: 'Unboxing your E-MAX Shadows Pro',
	},
	{
		id: '2_p0Jt4wtpY',
		si: 'P3-jEmt4SEAswy7M-UW',
		title: 'Caldwell Flash Bang | Key Features',
	},
];

const Cluster = () => {
	return (
		<Box
			display={'grid'}
			gap={2}
			sx={{
				gridTemplateColumns: { sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)' },
				gridAutoRows: { xs: '125px' },
				gridTemplateRows: { md: 'repeat(4, 151px)' },
			}}
		>
			{videoFeed.map((e, i) => (
				<React.Fragment>
					{i === 0 ? (
						<Box
							onClick={() => window.open(`https://www.youtube.com/watch?v=${e.id}`, '_blank')}
							sx={{
								backgroundColor: 'rgba(0,150,0,.1)',
								gridColumn: { md: '1/4' },
								gridRow: { md: '1/4' },
								position: 'relative',
								cursor: 'pointer',
							}}
						>
							<ClusterItem vId={e.id} vTitle={e.title} />
						</Box>
					) : (
						<Box
							onClick={() => window.open(`https://www.youtube.com/watch?v=${e.id}`, '_blank')}
							sx={{ backgroundColor: 'rgba(0,150,0,.1)', position: 'relative', cursor: 'pointer' }}
						>
							<ClusterItem vId={e.id} vTitle={e.title} />
						</Box>
					)}
				</React.Fragment>
			))}
		</Box>
	);
};

export default Cluster;
