import React, { useContext, useRef } from 'react';
//import { Context } from '../../AdvertisingContext';
import { ManufacturerContextState } from './../../../../../../../Context/ManufacturerContext';
import docImage from './../../../assets/document.svg';
import { Box, styled, useTheme } from '@mui/material';

import {
	ProductionTypes,
	ProductionProducts,
	production_types_products_links,
	Productions,
} from '../../../assets/ProductionProducts';
import DeleteItem from './DeleteItem';

// const CartWrapper = styled(Box)(({ theme }) => ({
// 	display: 'flex',
// 	justifyContent: 'space-between',
// 	padding: theme.spacing(2),
// 	gap: 10,
// 	alignItems: 'center',
// 	backgroundColor: 'white',
// 	borderRadius: '12px',
// 	marginBottom: theme.spacing(2),
// 	'&:last-of-type': {
// 		border: 'none',
// 	},
// }));

const CartWrapper = styled(Box)(({ theme }) => ({
	display: 'flex',
	padding: theme.spacing(2),
	gap: 10,
	alignItems: 'center',
	backgroundColor: 'white',
	borderRadius: '12px',
	marginBottom: theme.spacing(2),
	'&:last-of-type': {
		border: 'none',
	},
}));

const ContentWrapper = styled(Box)(({ theme }) => ({}));

const CartItem = ({ item }) => {
	const theme = useTheme();
	const { mfr } = useContext(ManufacturerContextState);

	const mfr_desc = useRef(mfr.find((e) => e.id === item.mfr_id).description);

	return (
		<CartWrapper>
			<Box>
				<img style={{ width: '80px' }} src={docImage} alt={item.ad_type_id} />
			</Box>
			<ContentWrapper sx={{ flex: 1 }}>
				<Box sx={{ color: theme.palette.primary.main, fontWeight: 'bold', fontSize: '1.2rem' }}>
					{mfr_desc.current}
				</Box>
				<Box sx={{ fontWeight: 'bold' }}>
					{Productions.find((e) => e.production_id === item.production_id).description} |{' '}
					{
						ProductionProducts.find((e) => e.production_products_id === item.production_products_id)
							.description
					}
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Box sx={{ flex: 1, fontWeight: 'bold', fontSize: '1.25em' }}>
						{`$${
							ProductionProducts.find((e) => e.production_products_id === item.production_products_id)
								.cost
						}`}
					</Box>
					<DeleteItem item={item} />
				</Box>
			</ContentWrapper>
		</CartWrapper>
	);
};

export default CartItem;
