import { Box, Typography } from '@mui/material';
import React from 'react';

const Header = ({ message, messageColor }) => {
  return (
    <Box display='flex' flexDirection='column' gap={2} sx={{ minHeight: '80px', p: 1 }}>
      <Box display='flex' alignItems='baseline' flexWrap='wrap' gap={1}>
        <Typography variant='h5' color='secondary'>
          Planograms
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
