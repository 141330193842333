import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import HornadyCard from './components/HornadyCard';

const MyComponent = () => {
	const { id: ItemId } = useParams();

	console.log('id: ' + ItemId);
	// State to store the data fetched from the API
	const [data, setData] = useState(null);
	// State to track loading state
	const [loading, setLoading] = useState(true);
	// State to track error
	const [error, setError] = useState(null);

	useEffect(() => {
		// Function to fetch data from the API
		const fetchData = async () => {
			try {
				// Make a GET request using Axios
				const response = await axios.get(`/productData/getHornadyPIMItemData/${ItemId}`);
				// Set the data in state
				setData(response.data);
				// Set loading state to false
				setLoading(false);
			} catch (error) {
				// If there's an error, set error state
				setError(error);
				// Set loading state to false
				setLoading(false);
			}
		};

		// Call the fetch data function
		fetchData();
	}, [ItemId]); // Empty dependency array ensures useEffect runs only once after component mounts

	return (
		<div>
			{loading ? (
				<p>Loading...</p>
			) : error ? (
				<p>Error: {error.message}</p>
			) : (
				<div>
					{/* Render your data here */}

					{JSON.stringify(data[0]) !== undefined ? (
						<>
							{console.log('data: ', data[0])}
							<HornadyCard item={data[0]} />
						</>
					) : (
						'Item Cant be Found!'
					)}
				</div>
			)}
		</div>
	);
};

export default MyComponent;
