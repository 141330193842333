import React, { useState } from 'react';
import { Box, Button, Divider, Grid, Menu, MenuItem, Typography, useTheme } from '@mui/material';

import { AccessAlarm, Search } from '@mui/icons-material';

const ITEM_HEIGHT = 48;

const CategoryDropDownMenu = ({ brandData }) => {
	console.log('cdd feed: ' + brandData.mfrId);
	const theme = useTheme();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (product_category_group_id, product_category_id, product_manufacturer_id) => {
		window.open(
			`https://www.theshootingwarehouse.com/?product_department_id=&product_category_group_id=${product_category_group_id}&product_category_id_${product_category_id}=true&product_manufacturer_id_${product_manufacturer_id}=true`,
			'_blank'
		);
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<React.Fragment>
			<Grid
				container
				xs={12}
				sx={{
					backgroundColor: 'white',
					position: 'relative',
					boxShadow: 1,
					mb: { xs: 3, sm: 0 },
					px: 1,
					py: 2,
					cursor: 'pointer',
					transition: '300ms ease-in-out',
					'&:hover': { backgroundColor: '#f4f4f4' },
				}}
				onClick={handleClick}
			>
				<Box sx={{ width: '55px', display: 'flex' }}>
					<AccessAlarm sx={{ m: 'auto', fontSize: '2.5em', color: theme.palette.primary.main }} />
				</Box>
				<Box sx={{ flex: 1, pr: 1 }}>
					<Typography sx={{ fontWeight: 'Bold' }}>Search Categories</Typography>
					<Divider sx={{ my: 0.5 }} />
					<Typography sx={{ fontSize: '.9em' }}>Search all categories</Typography>
				</Box>
				<Box
					sx={{
						position: 'absolute',
						top: '100%',
						right: '20px',
						transform: 'translateY(-50%)',
						borderRadius: '50%',
						backgroundColor: 'white',
						width: '35px',
						height: '35px',
						display: 'flex',
						border: 'solid 1px silver',
					}}
				>
					<Search sx={{ m: 'auto', color: theme.palette.primary.main }} />
				</Box>
			</Grid>
			{/* <Button
				id='category-dropdown-button'
				variant='contained'
				sx={{ display: 'flex', flexDirection: 'column', gap: 1, mx: 'auto' }}
				aria-controls={open ? 'category-dropdown-menu' : undefined}
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<AccessAlarm />
				Search Categories
			</Button> */}
			<Menu
				sx={{ top: '40px' }}
				id='category-dropdown-menu'
				aria-labelledby='category-dropdown-button'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 8.5,
						WIDTH: '50CH',
					},
				}}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				{brandData.categoryData.map((f, i) => (
					<MenuItem
						key={i}
						onClick={() =>
							handleMenuItemClick(f.product_category_group_id, f.product_category_id, brandData.mfrId)
						}
					>
						{f.description}
					</MenuItem>
				))}
				{/* <MenuItem onClick={handleClose}>Profile</MenuItem>
				<MenuItem onClick={handleClose}>My account</MenuItem>
				<MenuItem onClick={handleClose}>Logout</MenuItem> */}
			</Menu>
		</React.Fragment>
	);
};

export default CategoryDropDownMenu;
