import React from 'react';
import { Box, Container, useTheme } from '@mui/material';

const AboutUsPanel = () => {
	const theme = useTheme();
	return (
		<Container maxWidth='lg' sx={{ my: { xs: 2, sm: 5, lg: 4 } }}>
			<Box
				sx={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: { xs: '22px', lg: '40px' },
					mb: 2,
					color: theme.palette.primary.main,
				}}
			>
				PRECISION | DETERMINED | CONFIDENCE{' '}
			</Box>
			<Box
				sx={{
					textAlign: 'center',
					fontWeight: 'bold',
					fontSize: '16px',
					mb: { xs: 1, lg: 2 },
					color: 'rgb(170,170,175)',
					lineHeight: '24px',
				}}
			>
				For the marksman, Caldwell is a brand engineered for perfection to take your shot to the next level.
				Whether you’re shooting competitively or recreationally, we aim to make you the best. With quality
				products developed to provide you unmatched precision, you will have the confidence to take the shot
				when it matters most. We eliminate the variables that make you miss.
			</Box>
		</Container>
	);
};

export default AboutUsPanel;
