import React, { useContext } from 'react';

import { ManufacturerContextState } from './../../../../../../../Context/ManufacturerContext';
import { IconButton, useTheme } from '@mui/material';
import { FaTrash } from 'react-icons/fa';

const DeleteItem = ({ item }) => {
	const theme = useTheme();
	const { adCartData, setAdCartData } = useContext(ManufacturerContextState);

	const handleOnClick = () => {
		console.log('line id: ' + item.line_id);
		setAdCartData((prevState) => ({
			...prevState,
			items: prevState.items.filter((e) => e.line_id !== item.line_id),
		}));
	};
	return (
		<IconButton color='error' aria-label='add an alarm'>
			<FaTrash onClick={() => handleOnClick()} />
		</IconButton>
	);
};

export default DeleteItem;
