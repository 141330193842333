import React, { useEffect, useState } from 'react';
import { Box, Button, Container, styled } from '@mui/material';
import CarouselSlide from './components/CarouselSlide';
//import zIndex from '@mui/material/styles/zIndex';

const ChildrenWrapper = styled(Box)(({ theme }) => ({
	overflow: 'hidden',
	position: 'relative',
	width: 0,
	height: 0,
	minWidth: '100%',
	minHeight: '100%',
	maxWidth: '100%',
	maxHeight: '100%',

	'& > div > *': {
		width: '100%',
		height: '100%',
		flex: '0 0 100%',
		'& > *': {
			position: 'relative',
			zIndex: 10,
		},
	},
}));

const Carousel = ({
	containerWidth = 'xl',
	children,
	aspectRatio = '16/9',
	aspectRatioXS,
	aspectRatioSM,
	aspectRatioMD,
	aspectRatioLG,
	aspectRatioXL,
	marginLeft = 'auto',
	marginRight = 'auto',
	marginTop = 0,
	marginBottom = 0,
	dotsBottom = '15px',
	dotsLeft = 0,
}) => {
	const [activeSlide, setActiveSlide] = useState(null);
	const [leftMargin, setLeftMargin] = useState(0);
	const [carouselMounted, setCarouselMounted] = useState(0);
	const [slideCount, setSlideCount] = useState([]);

	const handelScrollingSlides = (e) => {
		let percent = 0;
		percent = e * 100;
		setLeftMargin(`-${percent}%`);
		console.log('e: ' + e);
		setActiveSlide(e);
	};

	useEffect(() => {
		const parent = document.getElementsByClassName('innerContent');
		if (parent.length > 0) {
			const parentElement = parent[0];
			const directDivs = [];

			// Get the first child element of parent
			let currentElement = parentElement.firstElementChild;

			// Iterate over siblings of parent
			while (currentElement) {
				// Check if the current sibling is a div
				directDivs.push(currentElement);
				// Move to the next sibling
				currentElement = currentElement.nextElementSibling;
			}

			setSlideCount(directDivs.length);
			setCarouselMounted(1);
		}
	}, []);

	useEffect(() => {
		console.log('active slide has changed: ' + activeSlide + ' total slides: ' + slideCount);
	}, [activeSlide, slideCount]);

	return (
		<Box
			sx={{
				width: '100%',
				aspectRatio: {
					xs: aspectRatioXS ? aspectRatioXS : aspectRatio,
					sm: aspectRatioSM ? aspectRatioSM : aspectRatio,
					md: aspectRatioMD ? aspectRatioMD : aspectRatio,
					lg: aspectRatioLG ? aspectRatioLG : aspectRatio,
					xl: aspectRatioXL ? aspectRatioXL : aspectRatio,
				},
				// mt: marginTop,
				// mr: marginRight,
				// mb: marginBottom,
				// ml: marginLeft,
				position: 'relative',
				opacity: carouselMounted,
			}}
			style={{ paddingLeft: 0, paddingRight: 0 }}
		>
			<ChildrenWrapper className='ChildrenWrapper'>
				<Box
					className='innerContent'
					sx={{
						display: 'flex',
						ml: leftMargin,
						transition: '700ms ease-in-out',
						maxWidth: '100%',
						height: '100%',
					}}
				>
					{children}
				</Box>
			</ChildrenWrapper>
			<Box
				sx={{
					position: 'absolute',
					bottom: dotsBottom,
					left: dotsLeft,
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					gap: 3,
				}}
			>
				{slideCount > 1 &&
					[...Array(slideCount)].map((e, idx) => (
						<Box
							sx={{
								backgroundColor: 'white',
								height: '7px',
								width: '70px',
								border: 'solid 1px black',
								opacity: '.7',
								borderRadius: '2px',
								cursor: 'pointer',
								transition: '300ms ease-in-out',
								'&:hover': { opacity: 1 },
							}}
							onClick={() => handelScrollingSlides(idx)}
						></Box>
					))}
			</Box>
		</Box>
	);
};

export default Carousel;
