'use client';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
//import screenfull from 'screenfull';

const videosFeed1 = [
	{
		src: 'https://samplelib.com/lib/preview/mp4/sample-5s.mp4',
		type: 'video/mp4',
		id: 100,
	},
	{
		src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
		type: 'video/mp4',
		id: 200,
	},
	{
		src: '/videos/11111.mp4',
		type: 'video/mp4',
		id: 300,
	},
];

const source = [
	{
		id: 1,
		src: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
		type: 'video/mp4',
		price: '1459.65',
	},
	{
		id: 2,
		src: '/videos/12345.mp4',
		type: 'video/mp4',
		price: '249.55',
	},
	{
		id: 3,
		src: '/videos/g43x.mp4',
		type: 'video/mp4',
		price: '718.50',
	},
];

// PRIMARY COMPONENT
const GunTV = () => {
	const [videos, setVideos] = useState(source);
	const [track, setTrack] = useState(0);
	const [price, setPrice] = useState(videos[0].price);

	const [url, setUrl] = useState(videos[0].src);

	const handelOnEnd = () => {
		let newValue = 0;

		const checkForUpdatedList = () => {
			videos !== source && setVideos(source);
			newValue = 0;
		};

		track === videos.length - 1 ? (newValue = 0) : (newValue = track + 1);
		setUrl(videos[newValue].src);
		setTrack(newValue);
		setPrice(videos[newValue]['price']);
		checkForUpdatedList();
	};

	useEffect(() => {
		//screenfull.request(document.querySelector('.tvWrapper'));
	}, []);

	// useEffect(() => {
	// 	let counter = 0;
	// 	const interval = setInterval(() => {
	// 		counter > 25 && source !== videosFeed1 && setVideos(videosFeed1);
	// 	}, 1000);
	// }, []);

	return (
		<div
			className='tvWrapper'
			style={{
				backgroundColor: 'black',
				position: 'absolute',
				top: '0',
				left: '0',
				width: '100%',
				height: '100%',
				zIndex: 10000,
			}}
		>
			<div
				style={{
					color: 'white',
					fontSize: '100px',
					fontWeight: 'bold',
					position: 'absolute',
					bottom: '0px',
					right: '0px',
					backgroundColor: 'rgba(0,0,0,.4)',
					padding: '10px 20px',
					border: 'solid 3px black',
					borderBottom: 'none',
					borderRight: 'none',
					borderTopLeftRadius: '24px',
					boxShadow: '-2px -2px 12px rgba(0,0,0,.4)',
				}}
			>
				${price}
			</div>

			{url !== null && (
				<ReactPlayer
					style={{ objectFit: 'cover' }}
					width='100%'
					height='100%'
					playing
					url={url}
					controls={false}
					volume={0}
					onEnded={() => handelOnEnd()}
				/>
			)}
		</div>
	);
};

export default GunTV;
