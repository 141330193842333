import React, { useState } from 'react';
export const ManufacturerContextState = React.createContext();

const ManufacturerContext = ({ children }) => {
	const [manufacturerMenu] = useState([
		{ id: 0, label: 'Dashboard', path: '/manufacturer/' },
		{ id: 1, label: 'Product Audit', path: '/manufacturer/audit' },
		{ id: 3, label: 'Advertising Contracts', path: '/manufacturer/advertising/purchase-advertising' },
		{ id: 4, label: 'Percentage Co-op', path: '/manufacturer/advertising/coop/co-op' },
		{ id: 5, label: 'Fixed Amount Co-op', path: '/manufacturer/advertising/coop/fixed-dollar' },
		{ id: 6, label: 'Contact Marketing', path: '/' },
	]);

	const [name, setName] = useState('Tony');
	const [mfr, setMfr] = useState([
		{ id: 451, description: '1791 Leather' },
		{ id: 2205, description: 'Accusharp' },
		{ id: 525, description: 'Agulia Ammunition' },
	]);
	const [modalOpen, setModalOpen] = useState(false);
	const [adCartData, setAdCartData] = useState({
		name: 'Context Johnny',
		items: [
			// {
			// 	ad_type_id: 100,
			// 	production_id: 1000,
			// 	mfr_id: 451,
			// 	production_products_id: 1000,
			// 	description: 'abc',
			// },
			// {
			// 	ad_type_id: 101,
			// 	production_id: 1100,
			// 	mfr_id: 2205,
			// 	production_products_id: 1100,
			// 	description: 'abc',
			// },
			// {
			// 	ad_type_id: 102,
			// 	production_id: 1200,
			// 	mfr_id: 525,
			// 	production_products_id: 1200,
			// 	description: 'abc',
			// },
			// {
			// 	ad_type_id: 103,
			// 	production_id: 1300,
			// 	mfr_id: 451,
			// 	production_products_id: 1300,
			// 	description: 'abc',
			// },
		],
	});
	return (
		<ManufacturerContextState.Provider
			value={{ name, setName, mfr, setMfr, modalOpen, setModalOpen, adCartData, setAdCartData, manufacturerMenu }}
		>
			{children}
		</ManufacturerContextState.Provider>
	);
};

export default ManufacturerContext;
