import React from 'react';
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import SmartOrders from '../Components/SmartOrders/SmartOrders';
import Returns from '../Components/Returns/Returns';
import TSWLogin from '../Components/Login/TSWLogin';
import TSWNavbar from '../Components/TSWNavbar/TSWNavbar';
import TSWBottomNav from '../Components/TSWBottomNav/TSWBottomNav';

const TSWRoutes = () => {
  const navbarHeight = '140px';
  const bottomNavHeight = '60px';

  return (
    <Box>
      <TSWNavbar />
      <Box
        sx={{
          paddingTop: navbarHeight,
          paddingBottom: bottomNavHeight,
          maxWidth: '1600px',
          margin: 'auto',
        }}
      >
        <Routes>
          <Route path='/login' element={<TSWLogin />} />
          <Route path='/smart-orders' element={<SmartOrders />} />
          <Route path='/returns' element={<Returns />} />
        </Routes>
      </Box>
      <TSWBottomNav />
    </Box>
  );
};

export default TSWRoutes;
