import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ManufacturerContextState } from './../../../../../Context/ManufacturerContext';
import { Box, Button, Container } from '@mui/material';
import AdCart from '../../Advertising/components/AdCart/AdCart';

const Buttons = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const { manufacturerMenu } = useContext(ManufacturerContextState);

	return (
		<Container maxWidth='xl' sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
			{manufacturerMenu.map((e, i) => (
				<Box key={e.id}>
					<Button
						variant='contained'
						color={
							location.pathname === e.path ||
							location.pathname === `${e.path.slice(0, -1)}` ||
							`${location.pathname.slice(0, -1)}` === e.path
								? 'error'
								: 'primary'
						}
						onClick={() => navigate(e.path)}
					>
						{e.label}
					</Button>
				</Box>
			))}
			<AdCart />
		</Container>
	);
};

export default Buttons;
