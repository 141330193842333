import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { MFRData } from './assets/mfrCategoryData';

import { Box } from '@mui/material';
import HeaderBanner from './components/HeaderBanner';
import FeatureProducts from './components/FeatureProducts';
import FeatureVideos from './components/FeatureVideos/FeatureVideos';
import HeaderBar from './components/HeaderBar';
import ButtonSearchGroup from './components/ButtonSearchGroup/ButtonSearchGroup';
import Rebates from './components/Rebates/Rebates';
import AboutUsPanel from './components/AboutUsPanel';
import MediaCloud from './components/MediaCloud/MediaCloud';
import Carousel from '../_GlobalComponents/Carousel/Carousel';
import CarouselSlide from '../_GlobalComponents/Carousel/components/CarouselSlide';
import BannerCarousel from './components/BannerCarousel/BannerCarousel';

const BrandLandingPage = () => {
	const { id } = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	//const id = queryParams.get('id');

	let brandData = MFRData(id);
	brandData = brandData.props.children;
	return (
		<Box id='BrandLandingPage'>
			{/* Header Bar with Logo and Web URL */}
			<HeaderBar
				maxWidth='xl'
				mfrId={brandData.mfrId}
				faceBook={brandData.faceBook}
				youTube={brandData.youTube}
				url={brandData.url}
			/>
			{/* Banner Carousel */}
			<BannerCarousel />
			{/* Header Banner */}
			{/* <HeaderBanner /> */}
			{/* Button Search Group */}
			<ButtonSearchGroup brandData={brandData} />
			{/* Manufacturer Slogan and About Us */}
			<AboutUsPanel />
			{/* Media Cloud */}
			<MediaCloud />
			{/* Feature Videos */}
			{/* <FeatureVideos /> */}
			{/* Feature Products */}
			{/* <FeatureProducts /> */}
			{/* Rebates */}
			<Rebates />
		</Box>
	);
};

export default BrandLandingPage;
