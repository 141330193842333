import React from 'react';

import { HornadyAttributekeys } from '../assets/HornadyFeed';
import MainComponent from '../BuildAttr';

import { Box, Button, Divider, Typography, useTheme } from '@mui/material';
import { Add } from '@mui/icons-material';

const Photo = ({ url, title, type }) => {
	//https://vumbnail.com/358629078.jpg

	//type === 'video' ? `${process({url.replace('https://vimeo.com/', 'https://vumbnail.com/')})}.jpg` : process(url);

	const process = (imageURL) => {
		return (
			<Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
				<img
					src={type === 'video' ? `${imageURL}.jpg` : imageURL}
					alt='title'
					style={{
						width: '80px',
						aspectRatio: '1/1',
						objectFit: 'contain',
						border: 'solid 1px #777',
						padding: '4px',
						borderRadius: '8px',
						backgroundColor: 'white',
					}}
				/>
				<Button
					sx={{ margin: '3px', textAlign: 'center' }}
					onClick={() => {
						window.open(url, '_blank');
					}}
				>
					{title}
				</Button>
			</Box>
		);
	};
	return type === 'video' ? process(url.replace('https://vimeo.com/', 'https://vumbnail.com/')) : process(url);
};

const Attr = ({ attrName, value }) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'nowrap',
				flex: () => {
					switch (attrName) {
						case 'ProductLine':
							return '0 0 320px';

						case 'Prop65Warn1':
							return '0 0 100%';

						default:
							return '0 0 250px';
					}
				},
			}}
		>
			<Typography sx={{ textColor: 'blue' }}>{attrName}</Typography> : <Typography>{value}</Typography>
		</Box>
	);
};

const HornadyCard = ({ item }) => {
	const theme = useTheme();
	return (
		<Box sx={{ display: 'flex', gap: 2, boxShadow: 4, p: 2, borderRadius: 2, height: '550px' }}>
			{/* MEDIA SECTION */}
			<Box
				sx={{
					flex: '0 0 250px',
					backgroundColor: '#e8e8e8',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box sx={{ height: 'auto', p: 1 }}>Media:</Box>
				<Box sx={{ flex: 1, overflow: 'auto' }}>
					<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
						{item.ProductFamilyImage !== undefined && (
							<Photo url={item.ProductFamilyImage} title='Family' />
						)}
						{item.ProductImage && <Photo url={item.ProductImage} title='Default' type='photo' />}
						{item.ProductImage1 && <Photo url={item.ProductImage1} title='Alt 1' type='photo' />}
						{item.ProductImage2 && <Photo url={item.ProductImage2} title='Alt 2' type='photo' />}
						{item.ProductImage3 && <Photo url={item.ProductImage3} title='Alt 3' type='photo' />}
						{item.ProductImage4 && <Photo url={item.ProductImage4} title='Alt 4' type='photo' />}
					</Box>
					<Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
						{item.Videos && <Photo url={item.Videos} title='Video' type='video' />}
						{item.Videos1 && <Photo url={item.Videos1} title='Video 1' type='video' />}
						{item.Videos2 && <Photo url={item.Videos2} title='Video 2' type='video' />}
						{item.Videos3 && <Photo url={item.Videos3} title='Video 3' type='video' />}
					</Box>
				</Box>
			</Box>

			{/* CONTENT SECTION */}
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
				<Box>
					{item.Brand} | {item.ProductID} | {item.UPC}
				</Box>
				<Box>
					{item.ProductLine} {item.ProductName}
				</Box>

				<Divider sx={{ py: 2 }} />
				<Box sx={{ px: 2, my: 2, flex: 1, overflow: 'auto' }}>{item.ProductDescription}</Box>
				<Divider sx={{ px: 2 }} />
				<Box sx={{ p: 1 }}>Prop 65 Warning</Box>
				<Box>{item.Prop65Warn1}</Box>
			</Box>

			{/* SIDE COLUMN */}
			<Box
				sx={{
					flex: '0 0  285px',
					backgroundColor: '#e7e7e7',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
					<Button variant='contained' sx={{ fontWeight: 'bold', fontSize: 'medium' }} endIcon={<Add />}>
						MSRP: ${item.Retail}
					</Button>
				</Box>
				<Box sx={{ p: 2 }}>Attributes:</Box>
				<Box
					sx={{
						display: 'flex',
						gap: 3,
						flexWrap: 'wrap',
						justifyContent: 'space-around',
						flex: 1,
						overflow: 'auto',
					}}
				>
					{MainComponent().map((k, idx) => item[k] && <Attr key={idx} attrName={k} value={item[k]} />)}
				</Box>
			</Box>
		</Box>
	);
};

export default HornadyCard;
