import { Modal, Paper } from '@mui/material';
import React from 'react';
import ReturnProcessTable from './ReturnProcessTable';

const ReturnProcess = ({ open, onClose, selectedInvoice }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          overflowY: 'auto',
          padding: '20px',
          backgroundColor: 'white',
          boxShadow: 24,
        }}
      >
        <ReturnProcessTable selectedInvoice={selectedInvoice} />
      </Paper>
    </Modal>
  );
};

export default ReturnProcess;
