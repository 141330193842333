import React from 'react';
import SettingsTable from './SettingsTable';

const SettingsView = () => {
  return (
    <>
      <SettingsTable />
    </>
  );
};

export default SettingsView;
