import React from 'react';
import { Badge, Box, Button, Divider, Grid, Typography } from '@mui/material';
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import SellTwoToneIcon from '@mui/icons-material/SellTwoTone';

const ButtonGroup = () => {
  return (
    <Grid container direction='row' spacing={3}>
      <Grid item xs={12}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <Button color='inherit' sx={{ textTransform: 'none' }}>
            <Typography
              variant='body1'
              sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
            >
              Welcome Ryan Kirk
            </Typography>
          </Button>
          <Divider orientation='vertical' sx={{ background: 'white', height: '25px', mx: 1 }} />
          <Button color='inherit' sx={{ textTransform: 'none' }}>
            <Typography
              variant='body1'
              sx={{ color: 'white', display: 'flex', alignItems: 'center' }}
            >
              <PowerSettingsNewTwoToneIcon sx={{ marginRight: '5px' }} />
              Log Out
            </Typography>
          </Button>
          <Divider orientation='vertical' sx={{ background: 'white', height: '25px', mx: 1 }} />

          <Button color='inherit' sx={{ textTransform: 'none' }}>
            <Typography variant='body1' sx={{ color: 'white' }}>
              Contact
            </Typography>
          </Button>
          <Divider orientation='vertical' sx={{ background: 'white', height: '25px', mx: 1 }} />

          <Button color='inherit' sx={{ textTransform: 'none' }}>
            <Typography variant='body1' sx={{ color: 'white' }}>
              About
            </Typography>
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Button color='inherit' sx={{ textTransform: 'none' }}>
            <Typography variant='h6' sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
              <SellTwoToneIcon sx={{ marginRight: '5px' }} />
              Retail Mode
            </Typography>
          </Button>

          <Button color='inherit' sx={{ textTransform: 'none' }}>
            <Typography variant='h5' sx={{ color: 'white', display: 'flex', alignItems: 'center' }}>
              <Badge badgeContent={1} color='secondary' sx={{ marginRight: '10px' }}>
                <ShoppingCartTwoToneIcon sx={{ fontSize: '150%' }} />
              </Badge>
              $10.00
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ButtonGroup;
