import React, { forwardRef, useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import axios from 'axios';

const OrderConfirmModal = forwardRef(
  ({ selectedRows, customerNumber, handleClose, handleOrderPlaced }, ref) => {
    const calculateTotalCost = () => {
      return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(
        selectedRows.reduce(
          (total, row) => total + (parseFloat(row.discount_price) || 0) * row.fill_qty,
          0
        )
      );
    };

    const [shippingOptions, setShippingOptions] = useState([]);
    const [selectedShippingOption, setSelectedShippingOption] = useState('');
    const [poNumber, setPoNumber] = useState('');

    useEffect(() => {
      axios.get('/suggestedOrders/shippingOptions').then((response) => {
        setShippingOptions(response.data);
        setSelectedShippingOption(response.data[0].shipping_method_id);
      });
    }, []);

    // Handle shipping option change
    const handleShippingChange = (event) => {
      setSelectedShippingOption(event.target.value);
    };

    // Handle PO number input change
    const handlePoNumberChange = (event) => {
      setPoNumber(event.target.value);
    };

    // Handle order submission
    const handleConfirm = () => {
      const items = selectedRows.map((row) => ({
        item_number: row.item_number,
        quantity: row.suggested_qty,
      }));

      // Find the shipping method name based on the selectedShippingOption (ID)
      const selectedShippingMethodName = shippingOptions.find(
        (option) => option.shipping_method_id === selectedShippingOption
      )?.shipping_method;

      const orderData = {
        customerNumber: customerNumber,
        poNumber: poNumber,
        shippingMethod: selectedShippingMethodName,
        items: { items },
      };

      axios
        .post('/suggestedOrders/placeOrder', orderData)
        .then((response) => {
          console.log('Order submitted successfully:', response.data);
          handleClose();
          handleOrderPlaced();
        })
        .catch((error) => {
          console.error('Error submitting order:', error);
        });
    };

    return (
      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '80vh',
        }}
      >
        {/* Header */}
        <Typography variant='h6' component='h2' gutterBottom>
          Confirm Your Order
        </Typography>

        {/* Scrollable Content */}
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label='order confirmation table'>
              <TableHead>
                <TableRow>
                  <TableCell>Item #</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align='right'>Cost</TableCell>
                  <TableCell align='right'>Quantity</TableCell>
                  <TableCell align='right'>Subtotal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRows.map((row) => (
                  <TableRow key={row.item_number}>
                    <TableCell>{row.item_number}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell align='right'>
                      $
                      {parseFloat(row.discount_price)
                        ? parseFloat(row.discount_price).toFixed(2)
                        : 'N/A'}
                    </TableCell>
                    <TableCell align='right'>{row.fill_qty}</TableCell>
                    <TableCell align='right'>
                      $
                      {parseFloat(row.discount_price)
                        ? (parseFloat(row.discount_price) * row.fill_qty).toFixed(2)
                        : 'N/A'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {/* Footer */}
        <Box sx={{ mt: 2 }}>
          {/* Shipping Options and PO Number */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Shipping Method</InputLabel>
              <Select
                value={selectedShippingOption}
                onChange={handleShippingChange}
                label='Shipping Method'
              >
                {shippingOptions.map((option) => (
                  <MenuItem key={option.shipping_method_id} value={option.shipping_method_id}>
                    {option.shipping_method}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label='PO Number'
              variant='outlined'
              value={poNumber}
              onChange={handlePoNumberChange}
            />
          </Box>

          {/* Total cost section */}
          <Box sx={{ display: 'flex', justifyContent: 'right', mb: 2 }}>
            <Typography variant='h6'> Total Cost: ${calculateTotalCost()}</Typography>
          </Box>

          {/* Action buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant='outlined' color='error' onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={handleConfirm}>
              Confirm Order
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
);

export default OrderConfirmModal;
