import React, { useState, useEffect } from 'react';
import { Box, Modal, TextField, Typography, Button } from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import axios from 'axios';
import TableRowActions from './Components/TableRowActions';
import getColumns from './Components/TableColumns';
import LoadingSpinner from '../../../_GlobalComponents/LoadingSpinner';
import TableHeaderActions from './Components/TableHeaderActions';
import OrderConfirmModal from '../OrderConfirmModal/OrderConfirmModal';
import RowDetailModal from './Components/RowDetailModal';

const ItemTable = ({ planogram }) => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedItemNumber, setSelectedItemNumber] = useState(null);

  useEffect(() => {
    const dataWithCheckbox = planogram.details.map((item) => ({
      ...item,
      isChecked: false,
    }));
    setData(dataWithCheckbox);
  }, [planogram.details]);

  const handleCheckChange = (row, isChecked) => {
    const updatedData = data.map((item) =>
      item.item_number === row.original.item_number ? { ...item, isChecked } : item
    );
    setData(updatedData);

    // Update selectedRows after changing isChecked
    const updatedSelectedRows = updatedData.filter((item) => item.isChecked);
    setSelectedRows(updatedSelectedRows);
  };

  const handleButtonClick = () => {
    if (selectedRows.length > 0) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 0);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleItemOpenModal = (itemNumber) => {
    setSelectedItemNumber(itemNumber);
    setModalOpen(true);
  };

  const handleItemCloseModal = () => {
    setModalOpen(false);
    setSelectedItemNumber(null);
  };

  const handleConfirmOrder = (poNumber, shippingMethod) => {
    console.log(poNumber, shippingMethod);
  };

  const handleOrderPlaced = () => {
    // Reset selected rows
    setSelectedRows([]);
    // Clear data so that old data is not displayed
    setData([]);
  };

  const handleQtyChange = (item_number, newQty) => {
    const updatedData = data.map((item) =>
      item.item_number === item_number ? { ...item, fill_qty: newQty } : item
    );
    setData(updatedData);

    // Update selectedRows after changing quantities
    const updatedSelectedRows = updatedData.filter((item) => item.isChecked);
    setSelectedRows(updatedSelectedRows);
  };

  const handleClearSelection = () => {
    // Uncheck all items in data
    const updatedData = data.map((item) => ({ ...item, isChecked: false }));
    setData(updatedData);
    setSelectedRows([]);
  };

  const handleSelectAll = () => {
    // Check all items in data
    const updatedData = data.map((item) => ({ ...item, isChecked: true }));
    setData(updatedData);
    setSelectedRows(updatedData);
  };

  const tableColumns = getColumns({ handleItemOpenModal, handleQtyChange });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', flexGrow: 1 }}>
      <MaterialReactTable
        columns={tableColumns}
        data={data}
        enableHiding={false}
        enableToolbarInternalButtons={false}
        initialState={{
          showColumnFilters: false,
          density: 'compact',
          pagination: { pageIndex: 0, pageSize: 20 },
        }}
        positionToolbarAlertBanner='bottom'
        autoResetPageIndex={false}
        enableStickyHeader
        enableRowActions
        enableToolbarInternalActions={false}
        enableColumnActions={false}
        renderLoadingOverlay={() => null}
        renderRowActions={({ row }) => (
          <TableRowActions row={row} onCheckChange={handleCheckChange} />
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <TableHeaderActions
            table={table}
            selectedRows={selectedRows}
            handleButtonClick={handleButtonClick}
            handleClearSelection={handleClearSelection}
            handleSelectAll={handleSelectAll}
            data={data}
            pageTitle={planogram.page_title}
            message={planogram.message}
            messageColor={planogram.message_color}
          />
        )}
        muiTablePaperProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
          },
        }}
        muiTableContainerProps={{
          sx: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            overflow: 'auto',
          },
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            size: 10,
          },
        }}
      />

      {/* Modal for confirming order */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <OrderConfirmModal
          selectedRows={selectedRows}
          handleClose={handleCloseModal}
          handleConfirm={handleConfirmOrder}
          customerNumber={''}
          handleOrderPlaced={handleOrderPlaced}
        />
      </Modal>
      <RowDetailModal
        open={modalOpen}
        handleClose={handleItemCloseModal}
        itemNumber={selectedItemNumber}
      />
    </Box>
  );
};

export default ItemTable;
