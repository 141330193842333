import React from 'react';
import PlanogramCard from './PlanogramCard';
import Grid2 from '@mui/material/Unstable_Grid2';

const CardList = ({ data }) => {
  return (
    <Grid2 container spacing={2}>
      <Grid2 item xs={12}>
        <PlanogramCard planogram={data} />
      </Grid2>
    </Grid2>
  );
};

export default CardList;
