import { Modal, Paper } from '@mui/material';
import React from 'react';
import NewReturnTable from './NewReturnTable';

const NewReturnModal = ({ open, onClose }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          overflowY: 'auto',
          padding: '20px',
          backgroundColor: 'white',
          boxShadow: 24,
        }}
      >
        <NewReturnTable />
      </Paper>
    </Modal>
  );
};

export default NewReturnModal;
