import React from 'react';
import Carousel from '../../../_GlobalComponents/Carousel/Carousel';
import CarouselSlide from '../../../_GlobalComponents/Carousel/components/CarouselSlide';
import SlideOne from './components/BannerCarouselSlides/SlideOne';
import SlideTwo from './components/BannerCarouselSlides/SlideTwo';
import SlideThree from './components/BannerCarouselSlides/SlideThree';
import SlideFour from './components/BannerCarouselSlides/SlideFour';
import SlideFive from './components/BannerCarouselSlides/SlideFive';
import { Box, Container } from '@mui/material';

const BannerCarousel = () => {
	return (
		<Box className='CarouselWrapper' sx={{ maxWidth: 'full', mx: 'auto' }}>
			<Carousel dotsBottom='60px' dotsLeft='0' aspectRatioXS='1/1' aspectRatioXL='24/6'>
				<CarouselSlide
					imgURL='https://images.unsplash.com/photo-1712337646541-d0c6f85447f8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
					backgroundOpacity='.2'
					backgroundColor='#557755'
					bannerNavURL='https://foxnews.com'
				>
					{/* <SlideOne /> */}
					abc
				</CarouselSlide>
				<CarouselSlide
					imgURL='https://images.unsplash.com/photo-1712371962905-94fb109193c7?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
					backgroundOpacity='.6'
					backgroundColor='black'
				>
					<SlideTwo />
				</CarouselSlide>
				<CarouselSlide
					imgURL='https://plus.unsplash.com/premium_photo-1668383773593-5598935c3fd4?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
					backgroundOpacity='.6'
					backgroundColor='black'
				>
					<SlideThree />
				</CarouselSlide>
				<CarouselSlide
					imgURL='/images/brandLandingPage/top-header.jpg'
					backgroundOpacity='1'
					backgroundColor='black'
				>
					<SlideFour />
				</CarouselSlide>
				<CarouselSlide
					imgURL='https://images.unsplash.com/photo-1712337646541-d0c6f85447f8?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
					backgroundOpacity='.3'
					backgroundColor='black'
				>
					<SlideFive />
				</CarouselSlide>
			</Carousel>
		</Box>
	);
};

export default BannerCarousel;
