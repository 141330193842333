import React from 'react';
import { Modal, Box, Paper } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import ItemTable from './ItemTable/ItemTable';

const PlanogramModal = ({ planogram, open, onClose }) => {
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        onClick={onClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          padding: 2,
        }}
      >
        <Paper
          onClick={handleContentClick}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            maxWidth: '90vw', // Adjusted for a slightly smaller modal width
            maxHeight: '80vh',
            overflow: 'hidden', // Hides overflow to keep clean borders
            borderRadius: 2,
            padding: 2,
          }}
        >
          <Box
            component='img'
            src={planogram.header_image}
            alt={planogram.page_title}
            sx={{
              borderRadius: 2,
              width: '30%', // Reduced image width to give more space to the table
              maxHeight: '100%',
              objectFit: 'contain',
              marginRight: 2,
            }}
          />
          <Box sx={{ flex: 1, overflow: 'auto', maxHeight: '100%' }}>
            <ItemTable planogram={planogram} />
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};

export default PlanogramModal;
