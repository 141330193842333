import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Divider, Modal, CircularProgress } from '@mui/material';
import axios from 'axios';

const RowDetailModal = ({ open, handleClose, itemNumber }) => {
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false); // State for high-res image modal

  useEffect(() => {
    if (open && itemNumber) {
      setIsLoading(true);
      axios
        .post(`/suggestedOrders/itemDetail`, {
          itemNumber,
        })
        .then((response) => {
          setProduct(response.data[0]); // Adjusting to handle response as an array
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Failed to fetch product details:', error);
          setIsLoading(false);
        });
    }
  }, [itemNumber, open]);

  // Function to open the high-res image modal
  const handleImageClick = () => {
    setIsImageModalOpen(true);
  };

  // Function to close the high-res image modal
  const handleImageModalClose = () => {
    setIsImageModalOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '800px',
            maxHeight: '80vh',
            overflow: 'auto',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </Box>
          ) : product ? (
            <Grid container spacing={3}>
              {/* Left side: Product image */}
              <Grid item xs={12} sm={4}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', // Ensure the image is centered within the available space
                    cursor: 'pointer',
                  }}
                  onClick={handleImageClick}
                >
                  <img
                    src={`https://media.server.theshootingwarehouse.com/small/${product.itemno}.jpg`}
                    alt={`${product.model} thumbnail`}
                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                  />
                </Box>
              </Grid>

              {/* Right side: Title and main details */}
              <Grid item xs={12} sm={8}>
                <Typography variant='h5' gutterBottom>
                  {product.brand_name} - {product.model}
                </Typography>
                <Typography variant='subtitle1' color='text.secondary' gutterBottom>
                  {product.series} - {product.category_group}
                </Typography>
                <Divider sx={{ my: 2 }} />

                {/* Main product specifications */}
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Item Number:
                    </Typography>
                    <Typography variant='body2'>{product.itemno}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='text.secondary'>
                      UPC:
                    </Typography>
                    <Typography variant='body2'>{product.upc}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Manufacturer SKU:
                    </Typography>
                    <Typography variant='body2'>{product.mfgino}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Department:
                    </Typography>
                    <Typography variant='body2'>{product.department}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant='body2' color='text.secondary'>
                      Category:
                    </Typography>
                    <Typography variant='body2'>{product.category}</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Specifications section */}
              <Grid item xs={12}>
                <Typography variant='h6' sx={{ mt: 2 }}>
                  Specifications
                </Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                  {product.facets &&
                    Object.keys(product.facets).map((facetKey) => (
                      <Grid item xs={6} md={4} key={facetKey}>
                        <Typography variant='body2' color='text.secondary'>
                          {facetKey}:
                        </Typography>
                        <Typography variant='body2'>{product.facets[facetKey]}</Typography>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Typography>No product details available</Typography>
          )}
        </Box>
      </Modal>

      {/* Modal for high-resolution image */}
      <Modal open={isImageModalOpen} onClose={handleImageModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            p: 2,
            borderRadius: 2,
            boxShadow: 24,
            maxWidth: '90%',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          <img
            src={`https://media.server.theshootingwarehouse.com/hires/${product?.itemno}.png`}
            alt={`${product?.model} high-res`}
            style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default RowDetailModal;
