import React from 'react';
import ReturnTable from './Components/ReturnTable';

const Returns = () => {
  return (
    <>
      <ReturnTable />
    </>
  );
};

export default Returns;
