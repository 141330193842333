import React, { useRef } from 'react';
import CartItems from './components/CartItems';

import { Box, Button, Divider, TextField, styled } from '@mui/material';
import TitleSubTitleHeader from '../../../../../../Components/_GlobalComponents/TitleSubTitleHeader';
import CartTotal from './components/CartTotal';

const CartWrapper = styled(Box)(({ theme }) => ({
	minWidth: '550px',
	padding: theme.spacing(2),
	backgroundColor: 'rgb(230,230,240)',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
}));

const Cart = () => {
	const FormDescription = useRef('');

	return (
		<CartWrapper>
			<TitleSubTitleHeader text='Advertising Contract' subtext='Publication Purchase' />

			<CartItems />

			<Divider sx={{ mb: 2 }} />

			<TextField
				sx={{ backgroundColor: 'white', borderRadius: '12px', mb: 3, border: 'none', outline: 'none' }}
				label='Contract Description'
				defaultValue={FormDescription.current}
				onChange={(e) => (FormDescription.current = e.target.value)}
			/>

			<Divider sx={{ mb: 2 }} />

			<CartTotal />

			<Button variant='contained' color='success' size='large' sx={{ fontWeight: 'bold', fontSize: '20px' }}>
				Make Purchase?
			</Button>
		</CartWrapper>
	);
};

export default Cart;
