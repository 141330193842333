import React from 'react';

import { Box, styled } from '@mui/material';
import Buttons from './components/Buttons';

// MUI STYLED ELEMENTS
const Wrapper = styled(Box)(({ theme }) => ({
	borderBottom: ' 1px solid #e7e7e7',
	padding: '24px 0',
}));

const ManufacturerNav = () => {
	return (
		<Wrapper>
			<Buttons />
		</Wrapper>
	);
};

export default ManufacturerNav;
