import { Grid, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import Login from './Components/Login';

const Landing = () => {
  const currentYear = new Date().getFullYear();
  const yearDifference = currentYear - 1841;
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={2}
      sx={{ height: '80vh', maxHeight: '80vh', p: 3, maxWidth: '1600px', margin: '0 auto' }}
    >
      <Grid
        item
        direction='column'
        xs={12}
        container
        justifyContent='center'
        alignContent='center'
        sx={{ flexGrow: 1 }}
      >
        <Typography
          variant='h3'
          sx={{
            color: theme.palette.primary.main,
            textAlign: 'center',
            textTransform: 'uppercase',
            letterSpacing: 8,
            p: 1,
          }}
        >
          Over <span style={{ fontWeight: 'bold' }}>{yearDifference} Years</span> of{' '}
          <span style={{ fontWeight: 'bold' }}>History</span>
        </Typography>
        <Typography variant='h5' sx={{ color: 'gray', textAlign: 'center', fontStyle: 'italic' }}>
          The Story of Our Family-Owned Business Spanning{' '}
          <span style={{ fontWeight: 'bold' }}>THREE</span> Centuries
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        container
        justifyContent='center'
        alignContent='center'
        sx={{ flexGrow: 1 }}
      >
        <Typography
          variant='h4'
          sx={{ color: theme.palette.secondary.main, textTransform: 'uppercase' }}
        >
          Integrity.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        container
        justifyContent='center'
        alignContent='center'
        sx={{ flexGrow: 1 }}
      >
        <Typography
          variant='h4'
          sx={{ color: theme.palette.secondary.main, textTransform: 'uppercase' }}
        >
          Family.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        container
        justifyContent='center'
        alignContent='center'
        sx={{ flexGrow: 1 }}
      >
        <Typography
          variant='h4'
          sx={{ color: theme.palette.secondary.main, textTransform: 'uppercase' }}
        >
          Tradition.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        container
        justifyContent='center'
        alignContent='center'
        sx={{ flexGrow: 1 }}
      >
        <Typography
          variant='h4'
          sx={{ color: theme.palette.secondary.main, textTransform: 'uppercase' }}
        >
          Drive.
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent='center'
        alignContent='center'
        sx={{ flexGrow: 1 }}
      >
        <Paper sx={{ height: '100%', width: '100%', textAlign: 'center', alignContent: 'center' }}>
          <Login />
        </Paper>
      </Grid>
      <Grid
        item
        xs={6}
        container
        justifyContent='flex-start'
        alignContent='flex-start'
        sx={{ flexGrow: 1 }}
      >
        <Typography variant='h6' sx={{ color: 'gray' }}>
          These are the principles that for over 183 years have driven our company through five
          generations of Dickson family leadership with one sole focus - distribution. In 1841, two
          Welsh immigrants opened an apothecary shop in the rural township of Shreveport. In its
          earliest days, the business supplied everything the pioneer was likely to need to survive
          in a frontier town: quinine to fight malaria, mustard to make plasters, and tools to fight
          varmints, scoundrels, and scallywags. Today the family tradition continues at Sports
          South. Every day, it meets the challenges set forth by its founding fathers by providing
          the right product to the right place at the right time.
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        container
        justifyContent='flex-start'
        alignContent='flex-start'
        sx={{ flexGrow: 1 }}
      >
        <Typography variant='h6' sx={{ color: 'gray' }}>
          As the country's oldest and largest distributor of outdoor sporting merchandise, Sports
          South maintains its leadership position through the relentless pursuit of new ideas and
          new thinking to drive long-term success and growth. With the nation's largest
          concentration of outdoor sports inventory from all the leading manufacturers under one
          roof, Sports South can deliver unmatched distribution services to its customers. If you
          haven't already, please give us the opportunity to prove it! Sports South is a family
          company and these values permeate its culture to all its employees and its customers. Hard
          work, education, and hands-on involvement, along with the assured well-being and
          livelihood of hundreds of employees, reinforce the company's dedication to its origin.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Landing;
